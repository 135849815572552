import { theme } from './mui-theme'

export const headTableStyle = {
  backgroundColor: 'secondary.main',
  color: theme.palette.primary.contrastText,
}

export const headTableBlueStyle = {
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}

export const headTableGreenStyle = {
  backgroundColor: theme.palette.custom.rainforest,
  color: theme.palette.primary.contrastText,
}

export const secondaryHeadTableStyle = {
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}

export const secondaryTableRowStyle = {
  backgroundColor: theme.palette.divider,
  borderColor: theme.palette.grey[100],
}

export const tabContainerStyle = {
  button: {
    color: theme.palette.custom.darkGray,
    '&.Mui-selected': {
      color: theme.palette.custom.darkGray,
      fontWeight: 'bold',
    },
  },
  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.custom.lightBlue,
    borderRadius: '1rem',
    height: '5px',
  },
}

export const customDropZone = {
  '.MuiBox-root': {
    minHeight: '135px',
  },
}

export const cellBorderRight = {
  borderRight: `2px solid ${theme.palette.divider}`,
}
