import {
  useAppointmentAgentMutation,
  useAppointmentCallbackMutation,
  useAppointmentQueueMutation,
  useAppointmentRestoreMutation,
  useAppointmentVisitTimeMutation,
  useLazyGetAppointmentByIdQuery,
  useChangeAppointmentPortMutation,
  useDeleteMutation,
  useAppointmentStatusMutation,
  useLazyGetAppointmentsQuery,
  useMoveAppointmentMutation,
  useNoShowAppointmentMutation,
  useCreateOtherResultMutation,
  useMarkAppointmentAsCallbackMutation,
  useDeleteOtherResultMutation,
  useCompletePendingTaskMutation,
} from '../../redux/api/appointments'
import {
  useGetOfficeHoursQuery,
  useLazyGetDaysNotAvailableQuery,
  useGetCruisesQuery,
  useLazyGetOfficeHoursQuery,
  useGetPersonsByCruiseQuery,
  useLazyGetPersonsByCruiseQuery,
} from '../../redux/api/cruises'
import {
  useGetPersonsByRoomQuery,
  useUpdateMutation,
  useLazyGetPersonsByRoomQuery,
} from '../../redux/api/persons'
import { useLazyGetAssignedAgentsQuery } from '../../redux/api/users'
import {
  IAppointment,
  IAppointmentAgentPayload,
  IAppointmentOtherEditPayload,
  IAppointmentOtherPayload,
  IAppointmentOtherResponse,
  IAppointmentQueueResponse,
  IAppointmentSimple,
  IAppointmentStatusUpdatePayload,
  IChangePortPayload,
  IMarkAppointmentAsCallBackPayload,
} from '../../types/appointments'
import { IPersonUpdatePayload, IPersonsByRoomResponse } from '../../types/persons'
import { IGenericResponse } from '../../types/generic'
import { EUserRole, ICruiseAgent } from '../../types/users'
import { QUEUE_ID } from '../../utils/constants'
import { useCreateMutation, useEditMutation } from '../../redux/api/bookings'
import {
  useCreateCardMutation,
  useEditCardMutation,
  useChangeCardStatusMutation,
  useTransferToFolioMutation,
} from '../../redux/api/cards'
import { IBookingPayload } from '../../types/bookings'
import {
  ICardPayload,
  ICardStatusUpdatePayload,
  ICardTransferredUpdatePayload,
} from '../../types/cards'
import { useLazyGetEventsByRoomQuery } from '../../redux/api/events'
import { useLazyGetMailDropByRoomQuery } from '../../redux/api/mailDrop'
import {
  useAddAttachmentMutation,
  useCreateQuoteMutation,
  useEditQuoteMutation,
  useLazyGetQuoteAttachmentQuery,
} from '../../redux/api/quotes'
import {
  IQuoteAttachmentRequest,
  IQuoteAttachmentResponse,
  IQuotePayload,
} from '../../types/quotes'
import { useAppSelector } from '../../redux/hooks'

export const useAppointment = (stateroom: number, dateToFetchHours: string) => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const isAgent = loggedUser?.role === EUserRole.AGENT
  const { data: cruisesData } = useGetCruisesQuery(undefined, {
    skip: isAgent,
  })
  const [invokeAppointmentQueue] = useAppointmentQueueMutation()
  const [invokeAppointmentCallback] = useAppointmentCallbackMutation()
  const { data: officeHours } = useGetOfficeHoursQuery(dateToFetchHours, {
    skip: !dateToFetchHours,
  })
  const [lazyGetDaysNotAvailable, { data: cruiseDates }] = useLazyGetDaysNotAvailableQuery()
  const [lazyGetAssignedAgents, { data: assignedAgents, isFetching: isLoadingAssignedAgents }] =
    useLazyGetAssignedAgentsQuery()
  const { data, isLoading } = useGetPersonsByRoomQuery(stateroom, {
    skip: stateroom === 0 || !stateroom,
  })

  const [
    getGuestByRoomTrigger,
    {
      data: guestsByRoom,
      isLoading: isLoadingSearchedGuests,
      isFetching: isFetchingSearchedGuests,
    },
  ] = useLazyGetPersonsByRoomQuery()

  const [
    getGuestByCruiseTrigger,
    {
      data: guestsByCruise,
      isLoading: isLoadingSearchedGuestsByCruise,
      isFetching: isFetchingSearchedGuestsByCruise,
    },
  ] = useLazyGetPersonsByCruiseQuery()

  const [assignAgent] = useAppointmentAgentMutation()
  const [getAppointmentByIdTrigger, { data: appointmentById, isFetching }] =
    useLazyGetAppointmentByIdQuery()
  const [getAppointments, { data: appointments, isFetching: isFetchingAppointments }] =
    useLazyGetAppointmentsQuery()
  const [
    getUnfilteredAppointments,
    { data: unfilteredAppointments, isFetching: isFetchingUnfilteredAppointments },
  ] = useLazyGetAppointmentsQuery()

  const [invokeAppointmentVisitTime] = useAppointmentVisitTimeMutation()
  const [invokeChangeAppointmentPort] = useChangeAppointmentPortMutation()

  const [updateGuest] = useUpdateMutation()
  const [updateStatus] = useAppointmentStatusMutation()
  const [deleteAppointmentById] = useDeleteMutation()
  const [restoreAppointment] = useAppointmentRestoreMutation()
  const [getOfficeHours, { data: lazyOfficeHours, isFetching: isFetchingOfficeHours }] =
    useLazyGetOfficeHoursQuery()
  const [markAppointmentAsCallback, { isLoading: isLoadingMarkAppointmentAsCallback }] =
    useMarkAppointmentAsCallbackMutation()

  const [moveAppointment] = useMoveAppointmentMutation()
  const [noShowAppointment] = useNoShowAppointmentMutation()
  const [invokeAppointmentOtherResult, { isLoading: isLoadingOtherResult }] =
    useCreateOtherResultMutation()
  const [invokeAppointmentDeleteOtherResult] = useDeleteOtherResultMutation()
  const [getEventsByRoomTrigger, { data: events, isFetching: isFetchingEvents }] =
    useLazyGetEventsByRoomQuery()
  const [createQuote, { isLoading: isCreatingSingleQuote }] = useCreateQuoteMutation()
  const [editQuoteTrigger, { isLoading: isEditingQuote }] = useEditQuoteMutation()
  const [addAttachment, { isLoading: isAddingAttachmentToQuote }] = useAddAttachmentMutation()
  const [getQuoteAttachmentTrigger, { isFetching: isFetchingQuoteAttachment }] =
    useLazyGetQuoteAttachmentQuery()

  const [getMaildropByRoomTrigger, { data: maildrop, isFetching: isFetchingMaildrop }] =
    useLazyGetMailDropByRoomQuery()
  const [completePendingTask] = useCompletePendingTaskMutation()
  const createAppointmentQueue = async (
    personData: IAppointment,
    onSaved?: (response: IAppointmentQueueResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IAppointmentQueueResponse = await invokeAppointmentQueue({
        ...personData,
        queue_id: QUEUE_ID,
      }).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const assignAppointmentAgent = async (
    appointmentAndAgent: IAppointmentAgentPayload,
    onSaved?: (response: IGenericResponse, agent: ICruiseAgent) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IAppointmentQueueResponse = await assignAgent(appointmentAndAgent).unwrap()
      if (onSaved) onSaved(response, appointmentAndAgent.agent)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const createAppointmentCallback = async (
    personData: IAppointment,
    onSaved?: (response: IAppointmentQueueResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IAppointmentQueueResponse = await invokeAppointmentCallback({
        ...personData,
        queue_id: QUEUE_ID,
      }).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const updatePerson = async (
    person: IPersonUpdatePayload,
    onSaved?: (response: IGenericResponse, person: IPersonUpdatePayload) => void,
    onError?: (error: string) => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await updateGuest(person).unwrap()
      if (onSaved) onSaved(response, person)
    } catch (error: any) {
      console.error('Exception invoking API', error)
      if (onError) onError(error)
    }
  }

  const updateStatusAppointment = async (
    appointmentStatus: IAppointmentStatusUpdatePayload,
    onSaved?: (response: IGenericResponse, newStatus: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await updateStatus(appointmentStatus).unwrap()
      if (onSaved) onSaved(response, appointmentStatus.status)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const moveAppointmentCallback = async (
    appts: IAppointmentSimple[],
    onSaved?: (response: IGenericResponse) => void,
  ) => {
    try {
      const response: IGenericResponse = await moveAppointment(appts).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error(error)
    }
  }

  const checkAppointmentAsNoShow = async (
    id: number,
    onSaved?: (response: IGenericResponse) => void,
  ) => {
    try {
      const response = await noShowAppointment(id).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error(error)
    }
  }

  const deleteAppointment = async (
    appointmentId: number,
    onSaved?: (response: IGenericResponse) => void,
    onError?: (error: string) => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await deleteAppointmentById(appointmentId).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError && error instanceof Error) onError(error.message)
    }
  }

  const restore = async (
    appointmentId: number,
    onSaved?: (response: IGenericResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await restoreAppointment(appointmentId).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const changeAppointmentPort = async (
    payload: IChangePortPayload,
    onSaved?: (appointment: IAppointment) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IAppointment = await invokeChangeAppointmentPort(payload).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const createOtherResult = async (
    payload: IAppointmentOtherPayload,
    onSaved?: (response: IAppointmentOtherResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IAppointmentOtherResponse = await invokeAppointmentOtherResult(
        payload,
      ).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const updateOtherResult = async (
    payload: IAppointmentOtherEditPayload,
    onSaved?: (response: IAppointmentOtherResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IAppointmentOtherResponse = await invokeAppointmentDeleteOtherResult(
        payload,
      ).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const setAppointmentAsCallback = async (
    payload: IMarkAppointmentAsCallBackPayload,
    onSaved?: (response: IGenericResponse) => void,
  ) => {
    try {
      const response = await markAppointmentAsCallback(payload).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error(error)
    }
  }

  const createNewQuote = async (
    payload: IQuotePayload,
    onSaved?: (response: IGenericResponse) => void,
  ) => {
    try {
      const response: IGenericResponse = await createQuote(payload).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error(error)
    }
  }

  const editQuote = async (
    payload: IQuotePayload,
    onSaved?: (response: IGenericResponse) => void,
  ) => {
    try {
      const response = await editQuoteTrigger(payload).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error(error)
    }
  }

  const addAttachmentToQuote = async (
    payload: any,
    onSaved?: (response: IGenericResponse) => void,
  ) => {
    try {
      const response: IGenericResponse = await addAttachment(payload).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error(error)
    }
  }

  const downloadQuoteAttachment = async (
    payload: IQuoteAttachmentRequest,
  ): Promise<IQuoteAttachmentResponse | undefined> => {
    try {
      return await getQuoteAttachmentTrigger(payload).unwrap()
    } catch (error) {
      console.error(error)
    }
    return undefined
  }

  const completePending = async (
    appointmentId: number,
    onSaved?: (response: IGenericResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await completePendingTask(appointmentId).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    addAttachmentToQuote,
    appointmentById,
    appointments,
    assignAppointmentAgent,
    assignedAgents,
    changeAppointmentPort,
    checkAppointmentAsNoShow,
    completePending,
    createAppointmentCallback,
    createAppointmentQueue,
    createNewQuote,
    createOtherResult,
    cruiseDates,
    cruisesData,
    data,
    deleteAppointment,
    downloadQuoteAttachment,
    editQuote,
    events,
    getAppointmentByIdTrigger,
    getAppointments,
    getEventsByRoomTrigger,
    getGuestByRoomTrigger,
    getGuestByCruiseTrigger,
    getMaildropByRoomTrigger,
    getOfficeHours,
    getUnfilteredAppointments,
    guestsByRoom,
    guestsByCruise,
    invokeAppointmentVisitTime,
    isAddingAttachmentToQuote,
    isCreatingSingleQuote,
    isEditingQuote,
    isFetchingAppointments,
    isFetchingEvents,
    isFetchingGetAll: isFetching,
    isFetchingMaildrop,
    isFetchingOfficeHours,
    isFetchingQuoteAttachment,
    isFetchingSearchedGuests,
    isFetchingSearchedGuestsByCruise,
    isFetchingUnfilteredAppointments,
    isLoading,
    isLoadingAssignedAgents,
    isLoadingMarkAppointmentAsCallback,
    isLoadingOtherResult,
    isLoadingSearchedGuests,
    isLoadingSearchedGuestsByCruise,
    lazyGetAssignedAgents,
    lazyGetDaysNotAvailable,
    lazyOfficeHours,
    maildrop,
    moveAppointmentCallback,
    officeHours,
    restore,
    setAppointmentAsCallback,
    unfilteredAppointments,
    updateOtherResult,
    updatePerson,
    updateStatusAppointment,
  }
}

export const useBooking = () => {
  const [create] = useCreateMutation()
  const [edit, { isLoading }] = useEditMutation()

  const createBooking = async (
    booking: IBookingPayload,
    onSaved?: (response: IGenericResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await create(booking).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const editBooking = async (
    booking: IBookingPayload,
    onSaved?: (response: IGenericResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await edit(booking).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    createBooking,
    editBooking,
    isLoading,
  }
}

export const getGuests = (stateroom: number, cruiseId: number) => {
  const { data, isLoading } = useGetPersonsByRoomQuery(stateroom, {
    skip: stateroom === 0 || !stateroom,
  })

  const { data: personsByCruise } = useGetPersonsByCruiseQuery(
    { cruiseId, room: stateroom },
    {
      skip: cruiseId === 0 || !cruiseId || stateroom === 0 || !stateroom,
    },
  )

  let persons: IPersonsByRoomResponse[] = personsByCruise || []

  data?.forEach((person) => {
    const guest = persons.find(
      (item) =>
        item.first_name.toLowerCase() === person.first_name.toLowerCase() &&
        item.last_name.toLowerCase() === person.last_name.toLowerCase(),
    )

    if (!guest) {
      persons = [
        ...persons,
        {
          first_name: person.first_name,
          last_name: person.last_name,
          person_id: person.guest_id ? Number(person.guest_id) : person.person_id,
          room: person.room,
          validated: person.validated,
        },
      ]
    }
  })

  return {
    persons,
    isLoading,
  }
}

export const useCard = () => {
  const [create] = useCreateCardMutation()
  const [edit] = useEditCardMutation()
  const [updateStatus] = useChangeCardStatusMutation()
  const [transferToFolio] = useTransferToFolioMutation()

  const createCard = async (
    card: ICardPayload,
    onSaved?: (response: IGenericResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await create(card).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const editCard = async (
    card: ICardPayload,
    onSaved?: (response: IGenericResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await edit(card).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const updateCardStatus = async (
    card: ICardStatusUpdatePayload,
    onSaved?: (response: IGenericResponse, status: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await updateStatus(card).unwrap()
      if (onSaved) onSaved(response, card.status)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const updateCardTransferredToFolio = async (
    card: ICardTransferredUpdatePayload,
    onSaved?: (response: IGenericResponse, transferred: boolean) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await transferToFolio(card).unwrap()
      if (onSaved) onSaved(response, card.transferredCard)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    createCard,
    editCard,
    updateCardStatus,
    updateCardTransferredToFolio,
  }
}
