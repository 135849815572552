import * as yup from 'yup'

export const buildNumericValidation = (fieldName: string) =>
  yup.object().shape({
    value: yup
      .number()
      .required(`${fieldName} is required`)
      .positive(`${fieldName} must be greater than zero`)
      .typeError(`${fieldName} must be a number`),
  })
