import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import {
  IAgentPayload,
  ICruiseAgent,
  IUserPayload,
  IGetUsersResponse,
  IChangePasswordPayload,
  IDeleteAgentPayload,
} from '../../types/users'
import { IGenericResponse } from '../../types/generic'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery,
  endpoints: (builder) => ({
    createUser: builder.mutation<IGenericResponse, IUserPayload>({
      query: (payload) => ({
        url: '/users',
        method: 'POST',
        body: payload,
      }),
    }),
    editUser: builder.mutation<IGenericResponse, IUserPayload>({
      query: (payload) => {
        const { id, ...rest } = payload
        return {
          url: `/users/${id}`,
          method: 'PUT',
          body: rest,
        }
      },
    }),
    remove: builder.mutation<IGenericResponse, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
    }),
    get: builder.query<IGetUsersResponse, void>({
      query: () => ({
        url: '/users',
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    changePassword: builder.mutation<IGenericResponse, IChangePasswordPayload>({
      query: (payload) => {
        const { id, password } = payload
        return {
          url: `/users/${id}`,
          method: 'PATCH',
          body: { password },
        }
      },
    }),
    getAssignedAgents: builder.query<ICruiseAgent[], number>({
      query: (cruiseId) => ({
        url: `/users/cruise-agents/${cruiseId}`,
        method: 'GET',
      }),
    }),
    assignAgent: builder.mutation<IGenericResponse, IAgentPayload>({
      query: (payload) => ({
        url: '/users/cruise-agents',
        method: 'POST',
        body: payload,
      }),
    }),
    unassignAgent: builder.mutation<IGenericResponse, IDeleteAgentPayload>({
      query: (payload) => ({
        url: `/users/cruise-agents/${payload.cruiseId}/${payload.userId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useCreateUserMutation,
  useEditUserMutation,
  useRemoveMutation,
  useGetQuery,
  useChangePasswordMutation,
  useLazyGetAssignedAgentsQuery,
  useAssignAgentMutation,
  useUnassignAgentMutation,
  useGetAssignedAgentsQuery,
} = usersApi
