import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import moment from 'moment'

import { IMaildropProps } from './types'
import GridSpinner from '../GridSpinner'
import { theme } from '../../styles/mui-theme'

const MailDropCard: React.FC<IMaildropProps> = ({ maildrop, isFetchingMaildrop }) => (
  <>
    {isFetchingMaildrop && <GridSpinner />}
    {!isFetchingMaildrop && maildrop?.length === 0 ? (
      <Alert severity="info">No maildrop for this state room.</Alert>
    ) : (
      <Paper elevation={3} style={{ marginBottom: 2, maxHeight: 240, overflow: 'auto' }}>
        {maildrop?.map((item) => (
          <Card
            key={item.idMailDrop}
            sx={{
              mb: 2,
              ml: 1,
              mr: 1,
              mt: 1,
              backgroundColor: theme.palette.custom.cyan,
            }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Product:
                      </Typography>{' '}
                      {item.marketingMaterialName}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} pl={0}>
                  <Stack direction="column" justifyContent="center" alignItems="Center" height={1}>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Date:
                      </Typography>{' '}
                      {moment.utc(item.mailDropDate).format('MMM Do')}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    <Typography fontWeight="bold" component="strong" fontSize={14}>
                      State Room:
                    </Typography>{' '}
                    {item.stateRoom}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Paper>
    )}
  </>
)

export default MailDropCard
