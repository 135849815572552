import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import moment from 'moment'
import { IEventCardProps } from './types'
import GridSpinner from '../GridSpinner'
import { theme } from '../../styles/mui-theme'

const EventCard: React.FC<IEventCardProps> = ({ events, isFetchingEvents }) => (
  <>
    {isFetchingEvents && <GridSpinner />}
    {!isFetchingEvents && events?.length === 0 ? (
      <Alert severity="info">No events for this state room.</Alert>
    ) : (
      !isFetchingEvents &&
      events &&
      events.length > 0 && (
        <Paper elevation={3} style={{ marginBottom: 2, maxHeight: 240, overflow: 'auto' }}>
          {events?.map((event) => (
            <Card
              key={event.eventId}
              sx={{
                mb: 2,
                ml: 1,
                mr: 1,
                mt: 1,
                backgroundColor: theme.palette.custom.cyan,
              }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Event Name:
                        </Typography>{' '}
                        {event.eventName}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={7} pl={0}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="Center"
                      height={1}>
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Date:
                        </Typography>{' '}
                        {moment.utc(event.eventDate).format('MMM Do')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Attendees:
                      </Typography>{' '}
                      {event.participantsCount}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Paper>
      )
    )}
  </>
)

export default EventCard
