import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EmojiFlagsOutlinedIcon from '@mui/icons-material/EmojiFlagsOutlined'
import SmartphoneIcon from '@mui/icons-material/SmartphoneOutlined'
import { IActionsColumnProps } from './types'
import { theme } from '../../styles/mui-theme'
import { EBookingType } from '../../types/bookings'

const AdditionalColumn: React.FC<IActionsColumnProps> = ({ appointment, openDialog }) => {
  const hasPendingTasks = appointment.bookings
    ? appointment.bookings.filter(
        (item) => item.appointment_id === appointment.appointment_id && item.pending_items === 1,
      ).length > 0
    : false

  // OWN BOOKINGS
  const ownAppBooking =
    appointment.bookings?.filter(
      (booking) =>
        booking.booking_type === EBookingType.APP_BOOKING && booking.isFromThisAppointment,
    ) ?? []

  if (hasPendingTasks) {
    return (
      <Tooltip title="This appointment has pending items.">
        <IconButton
          onClick={(e) => {
            openDialog(appointment)
            e.stopPropagation()
          }}>
          <EmojiFlagsOutlinedIcon
            sx={{
              color: theme.palette.custom.yellow,
            }}
            fontSize="medium"
          />
        </IconButton>
      </Tooltip>
    )
  }

  if (ownAppBooking.length > 0) {
    return (
      <SmartphoneIcon
        sx={{
          color: theme.palette.custom.tangerine,
        }}
        fontSize="small"
      />
    )
  }

  return <div />
}

export default AdditionalColumn
