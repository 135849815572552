import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useNavigate, useParams } from 'react-router-dom'
import { useEditVoyage } from './hook'
import GridSpinner from '../../../../components/GridSpinner'
import BasicDataForm from '../../../../components/VoyageForm/BasicDataForm'
import {
  IBasicDataFormData,
  IItineraryFormData,
  INewVoyageOfficeHours,
  INewVoyagePortInfo,
  IOfficeHoursFormData,
} from '../../../../components/VoyageForm/types'
import { useNewVoyage } from '../NewVoyage/hook'
import ItineraryForm from '../../../../components/VoyageForm/ItineraryForm'
import OfficeHoursForm from '../../../../components/VoyageForm/OfficeHoursForm'
import { IPort } from '../../../../types/ports'
import PortForm from '../../../../components/PortForm'
import notify from '../../../../utils/notify'
import { usePorts } from '../../Ports/hook'
import {
  ICallBackTimeCruisePayload,
  ICreateCruisePayload,
  INewVoyageData,
  IPortSequenceCruisePayload,
} from '../../../../types/cruises'
import SettingsLayout from '../../../../components/SettingsLayout'
import { useVoyages } from '../hook'

const EditVoyage = () => {
  const { products, vessels, ports, rdss, isLoading, create } = useNewVoyage()
  const { data, isLoading: isLoadingGetCruise, refetch } = useEditVoyage()
  const { refetch: refetchVoyages } = useVoyages()
  const { createEdit } = usePorts()
  const [activeStep, setActiveStep] = useState(0)
  const [portToEdit, setPortToEdit] = useState<IPort | undefined>()
  const [isOpenPortDialog, setIsOpenPortDialog] = useState(false)
  const [isLoadingPort, setIsLoadingPort] = useState(false)
  const [formData, setFormData] = useState<INewVoyageData>({
    vessel: 0,
    product: 0,
    name: '',
    rdss: 0,
    voyage_number: 0,
    charter: 0,
    stateroom_forecast: 0,
    penetration_goal: 0,
    target_card_goal: 0,
    embarkPort: 0,
    embarkDate: '',
    embarkTime: '',
    debarkPort: 0,
    debarkDate: '',
    debarkTime: '',
    portSequence: [],
    officeHours: [],
    callbacks: [],
  })
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      setFormData({
        vessel: data.vessel,
        product: data.product,
        name: data.name,
        rdss: data.rdss,
        voyage_number: data.voyage_number,
        charter: data.charter || 0,
        stateroom_forecast: data.stateroom_forecast,
        penetration_goal: data.penetration_goal,
        target_card_goal: data.target_card_goal,
        embarkPort: data.embarkPort,
        embarkDate: data.embarkDate,
        embarkTime: data.embarkTime,
        debarkPort: data.debarkPort,
        debarkDate: data.debarkDate,
        debarkTime: data.debarkTime,
        portSequence: data.portSequence,
        officeHours: data.officeHours,
        callbacks: data.callbacks,
      })
    }
  }, [data])

  useEffect(() => {
    if (selectedTimezone) {
      const newPortSequence = formData.portSequence.map((p) => {
        if (p.port === portToEdit?.port_id) {
          return { ...p, timezone: selectedTimezone }
        }

        return p
      })
      setFormData((oldData) => ({ ...oldData, portSequence: newPortSequence }))
    }
  }, [selectedTimezone])

  const onSuccessEditCruise = () => {
    notify.success('Voyage updated successfully')
    refetchVoyages()
    refetch()
    navigate('/settings/voyages')
  }

  const onSubmitBasicDataForm = (basicDataFormData: IBasicDataFormData) => {
    setFormData((oldData) => ({
      ...oldData,
      ...basicDataFormData,
    }))

    if (!data?.hasAppointments) {
      const difference = moment(basicDataFormData.debarkDate).diff(
        moment(basicDataFormData.embarkDate),
        'days',
      )

      if (
        formData.portSequence[0].port !== basicDataFormData.embarkPort ||
        formData.portSequence[0].startTime !== basicDataFormData.embarkTime ||
        moment(formData.portSequence[0].date).format('MM/DD/YYYY') !==
          moment(basicDataFormData.embarkDate).format('MM/DD/YYYY') ||
        formData.portSequence[formData.portSequence.length - 1].port !==
          basicDataFormData.debarkPort ||
        formData.portSequence[formData.portSequence.length - 1].startTime !==
          basicDataFormData.debarkTime ||
        moment(formData.portSequence[formData.portSequence.length - 1].date).format(
          'MM/DD/YYYY',
        ) !== moment(basicDataFormData.debarkDate).format('MM/DD/YYYY')
      ) {
        const oldPortSequence = [...formData.portSequence]
        oldPortSequence.pop()
        oldPortSequence.shift()

        const itinerary: INewVoyagePortInfo[] = [
          {
            ...formData.portSequence[0],
            date: basicDataFormData.embarkDate,
            port: basicDataFormData.embarkPort,
            startTime: basicDataFormData.embarkTime,
            endTime: formData.portSequence[0].endTime,
          },
        ]

        for (let index = 0; index < difference - 1; index += 1) {
          itinerary.push({
            ...oldPortSequence[index],
            port: oldPortSequence[index] ? oldPortSequence[index].port : NaN,
            date: moment(basicDataFormData.embarkDate)
              .clone()
              .add(index + 1, 'day')
              .toString(),
            startTime: oldPortSequence[index] ? oldPortSequence[index].startTime : '',
            endTime: oldPortSequence[index] ? oldPortSequence[index].endTime : '',
            canModify: true,
          })
        }

        itinerary.push({
          ...formData.portSequence[formData.portSequence.length - 1],
          port: basicDataFormData.debarkPort,
          date: basicDataFormData.debarkDate,
          startTime: formData.portSequence[formData.portSequence.length - 1].startTime,
          endTime: formData.portSequence[formData.portSequence.length - 1].endTime,
        })

        setFormData((oldData) => ({ ...oldData, portSequence: itinerary }))
      }
    }

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitItineraryForm = (itineraryData: IItineraryFormData) => {
    const { portSequence } = itineraryData
    setFormData((oldData) => ({
      ...oldData,
      portSequence,
    }))

    if (
      (formData.officeHours.length > 2 &&
        !formData.officeHours.every(
          (officeHour, index) => officeHour.port === portSequence[index].port,
        )) ||
      formData.officeHours.length !== portSequence.length
    ) {
      if (data?.hasAppointments) {
        const officeHours: INewVoyageOfficeHours[] = portSequence.map((port, index) => {
          if (index === 0 && formData.officeHours[0].port === portSequence[0].port) {
            return formData.officeHours[0]
          }

          if (
            index === portSequence.length - 1 &&
            formData.officeHours[formData.officeHours.length - 1].port ===
              portSequence[portSequence.length - 1].port
          ) {
            return formData.officeHours[formData.officeHours.length - 1]
          }

          return {
            date: port.date,
            port: port.port,
            hours: [
              {
                openHour: '',
                closeHour: '',
              },
            ],
          }
        })
        setFormData((oldData) => ({ ...oldData!, officeHours }))
      } else {
        const oldOfficeHours = [...formData.officeHours]
        oldOfficeHours.pop()

        const officeHours: INewVoyageOfficeHours[] = portSequence.map((port, index) => {
          if (index === 0 && formData.officeHours[0].port === portSequence[0].port) {
            return {
              ...formData.officeHours[0],
              date: port.date,
            }
          }

          if (index === 0 && formData.officeHours[0].port !== portSequence[0].port) {
            return {
              date: port.date,
              port: port.port,
              hours: [
                {
                  openHour: '',
                  closeHour: '',
                },
              ],
            }
          }

          if (
            index === portSequence.length - 1 &&
            formData.officeHours[formData.officeHours.length - 1].port ===
              portSequence[portSequence.length - 1].port
          ) {
            return {
              ...formData.officeHours[formData.officeHours.length - 1],
              date: port.date,
            }
          }

          if (
            index === portSequence.length - 1 &&
            formData.officeHours[formData.officeHours.length - 1].port !==
              portSequence[portSequence.length - 1].port
          ) {
            return {
              date: port.date,
              port: port.port,
              hours: [
                {
                  openHour: '',
                  closeHour: '',
                },
              ],
            }
          }

          if (oldOfficeHours[index]) {
            return {
              ...formData.officeHours[index],
              date: port.date,
            }
          }

          return {
            date: port.date,
            port: port.port,
            hours: [
              {
                openHour: '',
                closeHour: '',
              },
            ],
          }
        })
        setFormData((oldData) => ({ ...oldData!, officeHours }))
      }
    }

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitOfficeHoursForm = async (officeHoursFormData: IOfficeHoursFormData) => {
    const { officeHours } = officeHoursFormData
    setFormData((oldData) => ({ ...oldData, officeHours }))

    const portSequence: IPortSequenceCruisePayload[] = formData.portSequence.map((port) => ({
      port_id: port.port.toString(),
      arrival_date: `${moment(port.date).format('YYYY-MM-DD')} ${moment(
        port.startTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      departure_date: `${moment(port.date).format('YYYY-MM-DD')} ${moment(
        port.endTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      cruise_port_id: port.cruisePortId,
    }))

    const officeHoursPayload: ICallBackTimeCruisePayload[] = []

    officeHours.forEach((hour) => {
      hour.hours.forEach((internalHour) => {
        officeHoursPayload.push({
          date: moment(hour.date).format('YYYY-MM-DD'),
          open_hour: moment(internalHour.openHour, 'HH:mm').format('hh:mm A'),
          close_hour: moment(internalHour.closeHour, 'HH:mm').format('hh:mm A'),
        })
      })
    })

    const payload: ICreateCruisePayload = {
      ship_id: formData.vessel.toString(),
      destination_id: formData.product.toString(),
      description: formData.name,
      rdss_id: formData.rdss,
      voyage_number: formData.voyage_number,
      charter: formData.charter || 0,
      stateroom_forecast: formData.stateroom_forecast,
      penetration_goal: formData.penetration_goal,
      target_card_goal: formData.target_card_goal,
      start_port: formData.embarkPort.toString(),
      end_port: formData.debarkPort.toString(),
      start_date: `${moment(formData.embarkDate).format('YYYY-MM-DD')} ${moment(
        formData.embarkTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      end_date: `${moment(formData.debarkDate).format('YYYY-MM-DD')} ${moment(
        formData.debarkTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      port_sequence: portSequence,
      office_hours: officeHoursPayload,
      callback_times: [],
      cruise_id: parseInt(id!, 10),
    }

    await create(payload, onSuccessEditCruise)
  }

  const goBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const editPort = (portId: number) => {
    const item = ports?.find((p) => p.port_id === portId)
    setPortToEdit(item)
    setIsOpenPortDialog(true)
  }

  const onPortSaved = () => {
    notify.success('Port saved successfully.')
    setIsLoadingPort(false)
    setIsOpenPortDialog(false)
  }

  const onAcceptPort = async (thePort: IPort): Promise<void> => {
    setIsLoadingPort(true)
    setSelectedTimezone(thePort.timezone)
    await createEdit(
      {
        port_id: thePort.port_id,
        name: thePort.name,
        timezone: thePort.timezone,
        timezoneOffset: moment().tz(thePort.timezone).utcOffset().toString(),
        isEdit: !!portToEdit,
      },
      onPortSaved,
      () => setIsLoadingPort(false),
    )
  }

  const steps = [
    {
      label: 'Enter basic data for voyage',
      content: (
        <BasicDataForm
          products={products ?? []}
          vessels={vessels ?? []}
          ports={ports ?? []}
          rdss={rdss ?? []}
          values={{
            debarkDate: formData.debarkDate,
            debarkPort: formData.debarkPort,
            debarkTime: formData.debarkTime,
            embarkDate: formData.embarkDate,
            embarkPort: formData.embarkPort,
            embarkTime: formData.embarkTime,
            name: formData.name,
            rdss: formData.rdss,
            voyage_number: formData.voyage_number,
            charter: formData.charter || 0,
            product: formData.product,
            stateroom_forecast: formData.stateroom_forecast,
            penetration_goal: formData.penetration_goal ?? 0,
            target_card_goal: formData.target_card_goal ?? 0,
            vessel: formData.vessel,
          }}
          onSubmit={onSubmitBasicDataForm}
          hasAppointments={data?.hasAppointments}
        />
      ),
    },
    {
      label: 'Define itinerary',
      content: (
        <ItineraryForm
          ports={ports ?? []}
          itinerary={formData.portSequence}
          goBack={goBack}
          onSubmit={onSubmitItineraryForm}
          editPort={editPort}
        />
      ),
    },
    {
      label: 'Set daily office hours',
      content: (
        <OfficeHoursForm
          ports={ports ?? []}
          officeHours={formData.officeHours}
          isLoading={isLoading}
          onSubmit={onSubmitOfficeHoursForm}
          goBack={goBack}
        />
      ),
    },
  ]

  return (
    <SettingsLayout>
      <Container maxWidth={false}>
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
          Voyage Details
        </Typography>
        {isLoadingGetCruise ? (
          <GridSpinner />
        ) : (
          <>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === steps.length - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }>
                    {step.label}
                  </StepLabel>
                  <StepContent>{step.content}</StepContent>
                </Step>
              ))}
            </Stepper>

            <PortForm
              key={`form-edit-port-itinerary-${portToEdit?.port_id || isOpenPortDialog}`}
              port={portToEdit}
              isOpen={isOpenPortDialog}
              isLoading={isLoadingPort}
              onAccept={onAcceptPort}
              onReject={() => setIsOpenPortDialog(false)}
            />
          </>
        )}
      </Container>
    </SettingsLayout>
  )
}

export default EditVoyage
