import { useEffect, useState } from 'react'
import { Button, Tooltip, Fab, Box, Typography, Chip, Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import LockReset from '@mui/icons-material/LockReset'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useUser } from './hook'
import SettingsLayout from '../../../components/SettingsLayout'
import DynamicDataGrid from '../../../components/DynamicDataGrid'
import ResetPasswordForm from '../../../components/ResetPasswordForm'
import ConfirmDialog from '../../../components/ConfirmDialog'
import UserForm from '../../../components/UserForm'
import Notify from '../../../utils/notify'
import { IUser } from '../../../components/UserForm/types'
import { IUserPayload } from '../../../types/users'
import { IPassword } from '../../../components/ResetPasswordForm/types'
import { useAppDispatch } from '../../../redux/hooks'
import { useGetWizardQuery } from '../../../redux/api/utils'
import { setWizard } from '../../../redux/features/auth'

const Users = () => {
  const dispatch = useAppDispatch()
  const [user, setUser] = useState<IUser | undefined>()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false)
  const [isOpenChangePasswordDialog, setIsOpenChangePasswordDialog] = useState(false)

  const { createUser, editUser, removeUser, changePassword, users, isLoadingUsersData, refetch } =
    useUser()
  const { data, refetch: refetchWizard } = useGetWizardQuery()

  useEffect(() => {
    dispatch(setWizard(data))
  }, [data])

  const onCreate = () => {
    setUser(undefined)
    setIsOpenDialog(true)
  }

  const onEdit = (thisUser: IUser) => {
    setUser(thisUser)
    setIsOpenDialog(true)
  }

  const onRemove = (thisUser: IUser) => {
    setUser(thisUser)
    setIsConfirmOpen(true)
  }

  const onUserSaved = (message: string) => {
    Notify.success(message)
    setIsLoading(false)
    setIsOpenDialog(false)
    refetch()
    refetchWizard()
  }

  const onUserError = () => {
    setIsLoading(false)
  }

  const onPasswordChanged = (message: string) => {
    Notify.success(message)
    setIsLoadingChangePassword(false)
    setIsOpenChangePasswordDialog(false)
  }

  const onChangePasswordError = () => {
    setIsLoadingChangePassword(false)
  }

  const onAccept = async (theUser: IUser): Promise<void> => {
    setIsLoading(true)
    const userPayload = {
      id: theUser.id,
      first_name: theUser.firstName,
      last_name: theUser.lastName,
      username: theUser.userName,
      role: theUser.role,
      active: theUser.isEnabled,
      password: theUser.password,
    }

    if (!theUser.id) {
      await createUser(userPayload, onUserSaved, onUserError)
    } else {
      await editUser(userPayload, onUserSaved, onUserError)
    }
  }

  const onAcceptChangePassword = async (password: IPassword): Promise<void> => {
    setIsLoadingChangePassword(true)
    await changePassword(
      {
        id: user?.id!,
        password: password.password,
      },
      onPasswordChanged,
      onChangePasswordError,
    )
  }

  const onChangePassword = (thisUser: IUser) => {
    setUser(thisUser)
    setIsOpenChangePasswordDialog(true)
  }

  const onRemovalCompleted = (message: string) => {
    Notify.success(message)
    setIsConfirmOpen(false)
    refetch()
    refetchWizard()
  }

  const onAcceptRemove = async () => {
    await removeUser(user?.id!, onRemovalCompleted, () => setIsConfirmOpen(false))
  }

  const getRoleColor = (role: string): 'default' | 'info' | 'warning' => {
    switch (role) {
      case 'superadmin':
        return 'warning'
      case 'admin':
        return 'info'
      default:
    }
    return 'default'
  }

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'FULL NAME',
      headerAlign: 'left',
      flex: 1,
    },
    {
      field: 'userId',
      headerName: 'USER ID',
      headerAlign: 'left',
      flex: 0.5,
    },
    {
      field: 'role',
      headerAlign: 'center',
      align: 'center',
      headerName: 'ROLE',
      minWidth: 130,
      width: 130,
      renderCell: ({ row }) => (
        <Chip
          label={row.role === 'admin' ? 'manager' : row.role}
          color={getRoleColor(row.role)}
          sx={{ width: 130, textTransform: 'uppercase' }}
        />
      ),
    },
    {
      field: 'enabled',
      headerName: 'STATUS',
      headerAlign: 'center',
      align: 'center',
      width: 110,
      minWidth: 110,
      renderCell: ({ row: rowLocal }) =>
        rowLocal.enabled === 1 ? (
          <Chip label="ENABLED" color="success" />
        ) : (
          <Chip label="DISABLED" />
        ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 180,
      minWidth: 180,
      renderCell: ({ row }) => (
        <>
          <Tooltip title="Reset Password">
            <Fab size="small" color="secondary" onClick={() => onChangePassword(row)}>
              <LockReset />
            </Fab>
          </Tooltip>
          <Tooltip title="Edit User" sx={{ ml: 1 }}>
            <Fab color="secondary" size="small" onClick={() => onEdit(row.userData)}>
              <EditOutlinedIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Delete User" sx={{ ml: 1 }}>
            <Fab color="error" size="small" onClick={() => onRemove(row.userData)}>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </>
      ),
    },
  ]

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Box>
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={onCreate}>
            New User
          </Button>
        </Box>
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Users Management
        </Typography>
      </Stack>

      <DynamicDataGrid
        rows={users?.rows.map((userData: IUserPayload) => ({
          id: userData.id,
          fullName: `${userData.first_name} ${userData.last_name}`,
          userId: userData.username,
          role: userData.role,
          enabled: userData.active,
          userData: {
            id: userData.id,
            firstName: userData.first_name,
            lastName: userData.last_name,
            userName: userData.username,
            role: userData.role,
            isEnabled: userData.active,
          },
        }))}
        emptyMessage="No users have been created yet."
        columns={columns}
        pageSize={20}
        isLoading={isLoadingUsersData}
      />

      <UserForm
        key={`form-user-${user?.id || isOpenDialog}`}
        isOpen={isOpenDialog}
        isLoading={isLoading}
        user={user}
        onAccept={onAccept}
        onReject={() => setIsOpenDialog(false)}
      />
      <ResetPasswordForm
        key={`form-password-${user?.id}-${isOpenChangePasswordDialog}`}
        isOpen={isOpenChangePasswordDialog}
        isLoading={isLoadingChangePassword}
        onAccept={onAcceptChangePassword}
        onReject={() => setIsOpenChangePasswordDialog(false)}
      />
      <ConfirmDialog
        title="Delete User"
        message="Are you sure you want to remove this user?"
        onAccept={onAcceptRemove}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
        isOpen={isConfirmOpen}
      />
    </SettingsLayout>
  )
}

export default Users
