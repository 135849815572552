import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { IUpdateVoyageAttributeFormProps } from './types'
import { buildNumericValidation } from './validations'
import { IVoyageAttributePayload } from '../../types/cruises'
import { MAX_INTEGER } from '../../utils/constants'

const UpdateVoyageAttributeForm: React.FC<IUpdateVoyageAttributeFormProps> = ({
  isOpen,
  isLoading,
  initialValue,
  onAccept,
  onReject,
  title,
  label,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IVoyageAttributePayload>({
    resolver: yupResolver(buildNumericValidation(label)),
    defaultValues: { value: initialValue },
  })

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            label={label ?? 'Goal'}
            type="number"
            InputProps={{
              inputProps: { max: MAX_INTEGER, min: 1 },
            }}
            error={!!errors.value}
            helperText={errors?.value?.message as string}
            {...register('value', { required: true })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default UpdateVoyageAttributeForm
