import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

import { IOtherCardProps } from './types'
import { calculateDateToRender } from '../../utils/date'
import { theme } from '../../styles/mui-theme'
import { getStatusName } from '../../utils/utils'

const OtherCard: React.FC<IOtherCardProps> = ({
  noShow,
  others,
  portTimeZone,
  appointmentId,
  handleDelete,
}) =>
  others.length === 0 ? (
    <Alert severity="info">
      {noShow
        ? 'No "No show" records for this appointment'
        : 'No other results for this appointment'}
    </Alert>
  ) : (
    <Paper elevation={3} style={{ marginBottom: 2, maxHeight: 240, overflow: 'auto' }}>
      {others.map((other) => (
        <Card
          key={other.other_id}
          sx={{
            mb: 2,
            ml: 1,
            mr: 1,
            mt: 1,
            backgroundColor:
              appointmentId === other.appointment_id
                ? theme.palette.custom.backgroundGrey
                : theme.palette.custom.backgroundOrange,
          }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                  <Typography>{getStatusName(other.result!)}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={7} pl={0}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  height={1}>
                  <Typography fontWeight="bold" fontSize={14}>
                    {`${other.agent?.first_name} ${
                      other.agent?.last_name
                    } on ${calculateDateToRender(portTimeZone, other.other_on!)}`}
                  </Typography>{' '}
                  {other.other_comment && (
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Comment:
                      </Typography>{' '}
                      {other.other_comment}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={2}>
                {appointmentId === other.appointment_id && (
                  <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                    <IconButton onClick={() => handleDelete(other.other_id!)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Paper>
  )

export default OtherCard
