import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import { Link, Paper } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { useCasino } from './hook'
import SettingsLayout from '../../../components/SettingsLayout'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { headTableStyle } from '../../../styles/generic-styles'
import GridSpinner from '../../../components/GridSpinner'
import Notify from '../../../utils/notify'
import UploadFileForm from '../../../components/UploadFileForm'
import { IUploadFileForm } from '../../../components/UploadFileForm/types'

const SettingsCasino = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [isUploadFormOpen, setIsUploadFormOpen] = useState(false)
  const [recordToDelete, setRecordToDelete] = useState('')

  const { data, isLoading, createCasinoRecord, refetch, removeCasinoRecord } = useCasino()

  const onCreateComplete = (message: string) => {
    Notify.success(message)
    setIsUploadFormOpen(false)
    setIsLoadingUpload(false)
    refetch()
  }

  const onCreateError = () => {
    setIsUploadFormOpen(false)
    setIsLoadingUpload(false)
  }

  const onAccept = async (values: IUploadFileForm): Promise<void> => {
    setIsLoadingUpload(true)
    const form = new FormData()
    form.append('name', values.name)
    form.append('file', values.file[0])
    await createCasinoRecord(form, onCreateComplete, onCreateError)
  }

  const onSelectToRemove = (fileName: string) => {
    setRecordToDelete(fileName)
    setIsConfirmOpen(true)
  }

  const onRemovalCompleted = (message: string) => {
    Notify.success(message)
    setIsConfirmOpen(false)
    refetch()
  }

  const onAcceptRemove = async () => {
    await removeCasinoRecord(recordToDelete, onRemovalCompleted, () => setIsConfirmOpen(false))
  }

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Stack direction="row">
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setIsUploadFormOpen(true)}>
            UPLOAD
          </Button>
        </Stack>
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Casino Management
        </Typography>
      </Stack>
      <Paper>
        <Alert severity="info">
          <Link href="/casino-template.csv" download>
            Download this template
          </Link>{' '}
          and fill in to upload new casino records.
        </Alert>
      </Paper>
      <TableContainer
        sx={{
          maxHeight: 'calc(100vh - 340px)',
          backgroundColor: 'primary.contrastText',
          marginTop: 2,
        }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={headTableStyle}>NAME</TableCell>
              <TableCell
                sx={{
                  ...headTableStyle,
                  minWidth: 180,
                  width: 180,
                }}>
                DATE
              </TableCell>
              <TableCell
                sx={{
                  ...headTableStyle,
                  minWidth: 60,
                  width: 60,
                }}
                align="center">
                ROOMS
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...headTableStyle,
                  minWidth: 60,
                  width: 60,
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              data?.success &&
              data.data.map((item, idx) => (
                <TableRow key={`table-row-casino-${idx}`}>
                  <TableCell>{item.fileName}</TableCell>
                  <TableCell>{moment.utc(item.createDateTime).format('MMMM Do, YYYY')}</TableCell>
                  <TableCell align="center">{item.staterooms.length}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Delete casino record">
                      <Fab
                        color="error"
                        size="small"
                        onClick={() => onSelectToRemove(item.fileName)}>
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(isLoading || isLoadingUpload) && <GridSpinner />}
      {!isLoading && data && !data.success && (
        <Alert severity="info">No casino records in this voyage yet.</Alert>
      )}

      <UploadFileForm
        key={`form-casino-${isUploadFormOpen}`}
        title="Upload Casino File"
        isOpen={isUploadFormOpen}
        onReject={() => {
          setIsUploadFormOpen(false)
        }}
        isLoading={isLoadingUpload}
        onAccept={onAccept}
        hideDatePicker
      />

      <ConfirmDialog
        title="Delete Casino Record"
        message="Are you sure you want to remove this casino record? Please notice this action cannot be undone."
        onAccept={onAcceptRemove}
        isOpen={isConfirmOpen}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
      />
    </SettingsLayout>
  )
}

export default SettingsCasino
