import { useContext, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Navigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { validationSchema } from './validations'
import { useLogin } from './hook'
import { SocketContext } from '../../utils/socket'
import { PublicLayoutContainer } from '../../components/Layout/styles'
import { UseSettings } from '../Settings/Settings/hook'

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const { csrfToken, isLoadingGetCsrf } = UseSettings()
  const socket = useContext(SocketContext)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { token, isLoading, invokeEndpoint, loggedUser } = useLogin()

  useEffect(() => {
    if (loggedUser)
      socket.emit('registerUser', {
        firstName: loggedUser.firstName,
        lastName: loggedUser.lastName,
        userId: loggedUser.userId,
      })
  }, [loggedUser])

  if (token) return <Navigate to="/" />

  const onSubmit = (data: FieldValues) => {
    if (csrfToken) {
      setErrorMessage('')
      invokeEndpoint(
        {
          username: data.username,
          password: data.password,
          csrfToken: csrfToken.token,
        },
        setErrorMessage,
      )
    }
  }

  return (
    <PublicLayoutContainer>
      <Paper elevation={3} sx={{ p: 4, minWidth: 300, maxWidth: 500, alignSelf: 'center' }}>
        <Stack alignItems="center">
          <Box
            component="img"
            sx={{ width: 300 }}
            alt="Celebrity Cruises"
            src="/images/next-cruise-logo.png"
          />
          <Typography component="h1" variant="h6" sx={{ mb: 4, mt: 2 }}>
            NextCruise - SalesTrax
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              autoComplete="username"
              autoFocus
              error={!!errors.username}
              helperText={errors?.username?.message as string}
              {...register('username', { required: true })}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors?.password?.message as string}
              {...register('password', { required: true })}
            />
            {errorMessage && (
              <Alert sx={{ width: '100%', mt: 1, mb: 1 }} severity="warning">
                {errorMessage}
              </Alert>
            )}
            <LoadingButton
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              loading={isLoading || isLoadingGetCsrf}>
              Sign In
            </LoadingButton>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4 }}>
              {`Version ${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Box>
        </Stack>
      </Paper>
    </PublicLayoutContainer>
  )
}

export default Login
