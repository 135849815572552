import { useLazyGetMonthlyStatsQuery, useLazyGetStatsQuery } from '../../redux/api/stats'
import {
  useCreateOrUpdateMonthlyPenetrationGoalMutation,
  useCreateOrUpdateMonthlyStateroomForecastMutation,
  useUpdatePenetrationGoalMutation,
  useUpdateStateroomForecastMutation,
} from '../../redux/api/cruises'
import { ICruiseTargetMonthlyPayload, IVoyageAttributePayload } from '../../types/cruises'

export const useDashboard = () => {
  const [lazyGetStats, { data, isFetching }] = useLazyGetStatsQuery()
  const [lazyGetMonthlyStats, { data: monthlyStats, isFetching: isMonthlyStatsFetching }] =
    useLazyGetMonthlyStatsQuery()
  const [putStateroomForecast] = useUpdateStateroomForecastMutation()
  const [putPenetrationGoal] = useUpdatePenetrationGoalMutation()
  const [postMonthlyStateroomForecast] = useCreateOrUpdateMonthlyStateroomForecastMutation()
  const [postMonthlyPenetrationGoal] = useCreateOrUpdateMonthlyPenetrationGoalMutation()

  const updateStateroomForecast = async (
    value: IVoyageAttributePayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await putStateroomForecast(value).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const updatePenetrationGoal = async (
    value: IVoyageAttributePayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await putPenetrationGoal(value).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const createOrUpdateMonthlyStateroomForecast = async (
    values: ICruiseTargetMonthlyPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await postMonthlyStateroomForecast(values).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const createOrUpdateMonthlyPenetrationGoal = async (
    values: ICruiseTargetMonthlyPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await postMonthlyPenetrationGoal(values).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    statsData: data,
    isLoading: isFetching || isMonthlyStatsFetching,
    monthlyStatsData: monthlyStats,
    updateStateroomForecast,
    updatePenetrationGoal,
    lazyGetStats,
    lazyGetMonthlyStats,
    createOrUpdateMonthlyStateroomForecast,
    createOrUpdateMonthlyPenetrationGoal,
  }
}
