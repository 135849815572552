import { createApi } from '@reduxjs/toolkit/dist/query/react'
import moment from 'moment'
import { baseQuery } from './baseQuery'
import {
  IActiveInactiveCruisePayload,
  IGetCruisesResponse,
  IGetDaysNotAvailableResponse,
  IGetOfficeHoursResponse,
} from '../../pages/Settings/Voyages/types'
import {
  IActiveCruiseResponse,
  IGetCruiseResponse,
  ICreateCruisePayload,
  IVoyageAttributePayload,
  ICruiseTargetMonthlyPayload,
  IPersonsByCruise,
  IGuestsByCruisePayload,
} from '../../types/cruises'
import { IGenericResponse } from '../../types/generic'
import {
  INewVoyageCallbackTimeLimits,
  INewVoyageOfficeHours,
  INewVoyagePortInfo,
} from '../../components/VoyageForm/types'

export const cruisesApi = createApi({
  reducerPath: 'cruisesApi',
  baseQuery,
  endpoints: (builder) => ({
    getCruises: builder.query<IGetCruisesResponse[], void>({
      query: () => ({
        url: '/cruises',
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getActive: builder.query<IActiveCruiseResponse, void>({
      query: () => ({
        url: '/cruises/active',
        method: 'GET',
      }),
    }),
    deleteCruise: builder.mutation<void, number>({
      query: (cruiseId) => ({
        url: `/cruises/${cruiseId}`,
        method: 'DELETE',
      }),
    }),
    activeInactiveCruise: builder.mutation<IGenericResponse, IActiveInactiveCruisePayload>({
      query: ({ action, cruiseId }) => ({
        url: `/cruises/${cruiseId}/${action}`,
        method: 'PUT',
      }),
    }),
    updateStateroomForecast: builder.mutation<IGenericResponse, IVoyageAttributePayload>({
      query: (payload) => ({
        url: `/cruises/stateroom-forecast`,
        method: 'PUT',
        body: payload,
      }),
    }),
    updatePenetrationGoal: builder.mutation<IGenericResponse, IVoyageAttributePayload>({
      query: (payload) => ({
        url: `/cruises/penetration-goal`,
        method: 'PUT',
        body: payload,
      }),
    }),
    createOrUpdateMonthlyStateroomForecast: builder.mutation<
      IGenericResponse,
      ICruiseTargetMonthlyPayload
    >({
      query: (payload) => ({
        url: `/cruises/stateroom-forecast`,
        method: 'POST',
        body: payload,
      }),
    }),
    createOrUpdateMonthlyPenetrationGoal: builder.mutation<
      IGenericResponse,
      ICruiseTargetMonthlyPayload
    >({
      query: (payload) => ({
        url: `/cruises/penetration-goal`,
        method: 'POST',
        body: payload,
      }),
    }),
    getCruise: builder.query<IGetCruiseResponse, string>({
      query: (cruiseId) => ({
        url: `/cruises/${cruiseId}`,
        method: 'GET',
      }),
      transformResponse: (response: IGetCruisesResponse) => {
        response.port_sequence.sort((a, b) => moment(a.arrival_date).diff(moment(b.arrival_date)))
        const portSequence: INewVoyagePortInfo[] = response.port_sequence.map((port, index) => ({
          date: moment.utc(port.arrival_date).format('YYYY-MM-DD'),
          port: port.port_id,
          endTime: moment.utc(port.departure_date).format('HH:mm'),
          startTime: moment.utc(port.arrival_date).format('HH:mm'),
          hasAppointments: port.hasAppointments,
          numberOfAppointments: port.numberOfAppointments,
          timezone: port.timezone,
          canModify: index !== 0 && index !== response.port_sequence.length - 1,
          cruisePortId: port.cruise_port_id,
        }))

        const hasAppointments = response.port_sequence.filter((p) => p.hasAppointments).length > 0

        response.office_hours.sort((a, b) => moment(a.date).diff(moment(b.date)))
        const officeHours = response.office_hours.reduce(
          (prev: INewVoyageOfficeHours[], current) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { date, open_hour, close_hour } = current
            const findObj = prev.find((item) => item.date === moment.utc(date).format('YYYY-MM-DD'))
            if (!findObj) {
              prev.push({
                date: moment.utc(date).format('YYYY-MM-DD'),
                hours: [
                  {
                    openHour: moment(open_hour, 'hh:mm A').format('HH:mm'),
                    closeHour: moment(close_hour, 'hh:mm A').format('HH:mm'),
                  },
                ],
                port: portSequence.find(
                  (port) => port.date === moment.utc(current.date).format('YYYY-MM-DD'),
                )!.port,
              })
            } else {
              findObj.hours.push({
                openHour: moment(open_hour, 'hh:mm A').format('HH:mm'),
                closeHour: moment(close_hour, 'hh:mm A').format('HH:mm'),
              })
            }
            return prev
          },
          [],
        )

        response.callback_times.sort((a, b) => moment(a.date).diff(moment(b.date)))
        const callbacks = response.callback_times.reduce(
          (prev: INewVoyageCallbackTimeLimits[], current) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { date, open_hour, close_hour, max_callbacks_per_hour } = current
            const findObj = prev.find((item) => item.date === moment.utc(date).format('YYYY-MM-DD'))
            if (!findObj) {
              prev.push({
                date: moment.utc(date).format('YYYY-MM-DD'),
                hours: [
                  {
                    openHour: open_hour,
                    closeHour: close_hour,
                    callbacksPerHour: max_callbacks_per_hour,
                  },
                ],
                port: portSequence.find(
                  (port) => port.date === moment.utc(current.date).format('YYYY-MM-DD'),
                )!.port,
              })
            } else {
              findObj.hours.push({
                openHour: open_hour,
                closeHour: close_hour,
                callbacksPerHour: max_callbacks_per_hour,
              })
            }
            return prev
          },
          [],
        )

        return {
          callbacks,
          debarkDate: moment.utc(response.end_date).format('YYYY-MM-DD'),
          debarkPort: response.end_port,
          debarkTime: moment.utc(response.end_date).format('HH:mm'),
          embarkDate: moment.utc(response.start_date).format('YYYY-MM-DD'),
          embarkPort: response.start_port,
          embarkTime: moment.utc(response.start_date).format('HH:mm'),
          name: response.description,
          rdss: response.rdss.id,
          voyage_number: response.voyage_number,
          charter: response.charter,
          officeHours,
          portSequence,
          product: response.destination.id,
          target: response.voyage_target,
          stateroom_forecast: response.stateroom_forecast,
          penetration_goal: response.penetration_goal,
          target_card_goal: response.target_card_goal,
          vessel: response.ship.id,
          hasAppointments,
        }
      },
    }),
    createCruise: builder.mutation<IGetCruisesResponse[], ICreateCruisePayload>({
      query: (payload) => ({
        url: '/cruises',
        method: 'POST',
        body: payload,
      }),
    }),
    getDaysNotAvailable: builder.query<IGetDaysNotAvailableResponse, void>({
      query: () => ({
        url: '/cruises/days-not-available-callback',
        method: 'GET',
      }),
    }),
    getOfficeHours: builder.query<IGetOfficeHoursResponse, string>({
      query: (date) => ({
        url: `/cruises/office-hours${date ? `?date=${date}` : ''}`,
        method: 'GET',
      }),
    }),
    getPersonsByCruise: builder.query<IPersonsByCruise[], IGuestsByCruisePayload>({
      query: ({ cruiseId, room }) => ({
        url: `/cruises/${cruiseId}/${room}/guests`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetCruisesQuery,
  useGetActiveQuery,
  useLazyGetActiveQuery,
  useDeleteCruiseMutation,
  useActiveInactiveCruiseMutation,
  useUpdateStateroomForecastMutation,
  useUpdatePenetrationGoalMutation,
  useCreateOrUpdateMonthlyStateroomForecastMutation,
  useCreateOrUpdateMonthlyPenetrationGoalMutation,
  useGetCruiseQuery,
  useCreateCruiseMutation,
  useGetPersonsByCruiseQuery,
  useLazyGetPersonsByCruiseQuery,
  useLazyGetDaysNotAvailableQuery,
  useGetOfficeHoursQuery,
  useLazyGetOfficeHoursQuery,
} = cruisesApi
