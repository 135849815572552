export const MAX_INTEGER = 2147483647

export const MAIN_NAV_HEIGHT = 5
export const VOYAGE_BAR_HEIGHT = 5
export const FOOTER_BAR_HEIGHT_PX = 88
export const VOYAGE_INFO_BAR_HEIGHT_PX = 91
export const QUEUE_ID = 1
export const WAIT_MINUTES_BAD = 10
export const WAIT_MINUTES_TOO_BAD = 15

export const bookingOriginsData = [
  'In-Office (Standard)',
  'App Book Later',
  'Book Later Form',
  'Casino Form',
  'Ship Tour',
  'Suite Lounge',
  'Crown Lounge',
  'Crew Bookings',
]

export const stateroomOptions = [
  'Interior',
  'Interior with Virtual balcony',
  'Promenade View Interior',
  'Neighborhood View Interior',
  'Oceanview Window',
  'Neighborhood Balcony',
  'Oceanview Balcony',
  'Infinite Oceanview Balcony',
  'Family Infinite Oceanview Balcony',
  'Junior Suite',
  'Sky Junior Suite',
  'Sunset Suite',
  'Surfside Family Suite',
  'Grand Suite',
  'Grand Suite – 2 Bedroom',
  'Owners Suite',
  'Owners Panoramic Suite',
  'Panoramic Suite (VP, VT, VO)',
  'Ultimate Panoramic Suite (UP)',
  'Aquatheater Suite – 1 Bedroom',
  'Aquatheater Suite – 2 Bedroom',
  'Crown Loft Suite',
  'Sky Loft Suite',
  'Grand Loft Suite',
  "Owner's Loft Suite",
  'Star Loft Suite',
  'Solarium Loft Suite',
  'Villa Suite',
  'Icon Loft Suite',
  'Royal Loft Suite',
  'Ultimate Family Suite',
  'Ultimate Family Townhouse',
  'Other (Add Comment)',
]

export const reasonsWhyData = [
  'Guest not ready to plan ship/sailing date',
  'Guest wanted to wait for New Deployment',
  'Guest wanted to wait for a better price/promotion',
  'No guest interaction / turned in booking form / Used app',
  "Booking for Guest's friends and/or family",
  'Additional booking for Guest',
]

export const currencyOptions = ['USD', 'Euro', 'GBP']

export const savingTypes = ['Dollars Off', 'OBC']

export const reasonOptions = [
  'Price Objection',
  'Need to talk with family / Think it over',
  'Dates uncertain / Need flexibility',
  'Ship Type',
  'Stateroom Availability',
  'Itinerary',
  'Promotion not rich enough',
  'Guest thinks they can get better price/offer elsewhere',
  'Other',
]

export const cardStatusOptions = ['APPROVED', 'PENDING', 'DENIED']

export const savingsTypeOptions = ['Dollars Off', 'Onboard Credit']

export const charterOptions = [
  { name: 'No', value: 0 },
  { name: 'yes', value: 1 },
]
