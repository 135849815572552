import { Box, Container, Paper, Stack, Tab } from '@mui/material'
import numeral from 'numeral'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useEffect, useState } from 'react'
import moment from 'moment'
import Alert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import AgentStatsTable from './AgentStatsTable'
import CruiseStatsTableMonthly from './CruiseStatsTableMonthly'
import CardStatsByAgentTable from './CardStats/StatsByAgentTable'
import CardStatsByGuestTable from './CardStats/StatsByGuestTable'
import BigNumber from './BigNumber'
import { useDashboard } from './hook'
import { EStatsTab, EVoyageAttribute } from './types'
import MonthYearSelector from './MonthYearSelector/index'
import { IGuestStats } from './CardStats/StatsByGuestTable/types'
import { IAgentResponse, IDailyResponse, IStatsPayload } from '../../types/stats'
import GridSpinner from '../../components/GridSpinner'
import UpdateVoyageAttributeForm from '../../components/UpdateTargetForm'
import Notify from '../../utils/notify'
import { ICruiseTargetMonthlyPayload, IVoyageAttributePayload } from '../../types/cruises'
import Wizard from '../../components/Wizard'
import { useAppSelector } from '../../redux/hooks'
import { EUserRole } from '../../types/users'
import { tabContainerStyle } from '../../styles/generic-styles'
import { theme } from '../../styles/mui-theme'
import { useCard } from '../Appointments/hook'
import { IGenericResponse } from '../../types/generic'
import { EStatusCard } from '../../types/cards'

const Dashboard = () => {
  // Variable to check location and error messages by redirection
  const location = useLocation()
  const navigate = useNavigate()
  const { updateCardStatus, updateCardTransferredToFolio } = useCard()

  const isActiveCruise = !!useAppSelector((state) => state.auth.activeCruise)
  const cruiseActive = useAppSelector((state) => state.auth.activeCruise)
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const isAgent = loggedUser && loggedUser.role === EUserRole.AGENT

  const [selectedTab, setSelectedTab] = useState(EStatsTab.VOYAGE)

  const today = moment()
  const currentDate = { month: today.month() + 1, year: today.year() }
  const [selectedDate, setSelectedDate] = useState<IStatsPayload>(currentDate)
  const [selectedBox, setSelectedBox] = useState<string>('offered')
  const [cardStats, setCardStats] = useState<IGuestStats[]>([])

  const [voyageAttributeToModify, setVoyageAttributeToModify] = useState(EVoyageAttribute.NONE)
  const [isVoyageAttributeToModifyLoading, setIsVoyageAttributeToModifyLoading] = useState(false)

  const {
    statsData,
    monthlyStatsData,
    isLoading,
    updateStateroomForecast,
    updatePenetrationGoal,
    lazyGetStats,
    lazyGetMonthlyStats,
    createOrUpdateMonthlyStateroomForecast,
    createOrUpdateMonthlyPenetrationGoal,
  } = useDashboard()

  useEffect(() => {
    if (!isActiveCruise) return
    if (selectedTab === EStatsTab.MONTHLY) {
      lazyGetMonthlyStats(selectedDate)
      lazyGetStats(selectedDate)
    } else if (selectedTab === EStatsTab.VOYAGE) {
      lazyGetStats(undefined)
    }
  }, [isActiveCruise, selectedTab, selectedDate])

  const onVoyageAttributeResponse = (message?: string) => {
    lazyGetStats()
    if (message) {
      Notify.success(message)
    }
    setIsVoyageAttributeToModifyLoading(false)
    setVoyageAttributeToModify(EVoyageAttribute.NONE)
  }

  const onAcceptAttribute = async (value: IVoyageAttributePayload) => {
    setIsVoyageAttributeToModifyLoading(true)
    switch (voyageAttributeToModify) {
      case EVoyageAttribute.STATEROOM_FORECAST:
        await updateStateroomForecast(value, onVoyageAttributeResponse, onVoyageAttributeResponse)
        break
      case EVoyageAttribute.PENETRATION_GOAL:
        await updatePenetrationGoal(value, onVoyageAttributeResponse, onVoyageAttributeResponse)
        break
      default:
    }
  }

  const onMonthlyVoyageAttributeResponse = (message?: string) => {
    lazyGetStats(selectedDate)
    if (message) {
      Notify.success(message)
    }
    setIsVoyageAttributeToModifyLoading(false)
    setVoyageAttributeToModify(EVoyageAttribute.NONE)
  }

  const onAcceptMonthlyVoyageAttribute = async ({ value }: IVoyageAttributePayload) => {
    const dateToQuery = selectedDate || currentDate

    const monthlyAttribute: ICruiseTargetMonthlyPayload = {
      month: dateToQuery.month,
      year: dateToQuery.year,
      shipId: cruiseActive?.ship.id!,
    }

    switch (voyageAttributeToModify) {
      case EVoyageAttribute.STATEROOM_FORECAST:
        monthlyAttribute.stateroom_forecast = value
        await createOrUpdateMonthlyStateroomForecast(
          monthlyAttribute,
          onMonthlyVoyageAttributeResponse,
          onMonthlyVoyageAttributeResponse,
        )
        break
      case EVoyageAttribute.PENETRATION_GOAL:
        monthlyAttribute.penetration_goal = value
        await createOrUpdateMonthlyPenetrationGoal(
          monthlyAttribute,
          onMonthlyVoyageAttributeResponse,
          onMonthlyVoyageAttributeResponse,
        )
        break
      default:
        lazyGetStats(selectedDate)
        Notify.error('Invalid attribute.')
        setIsVoyageAttributeToModifyLoading(false)
        setVoyageAttributeToModify(EVoyageAttribute.NONE)
    }
  }

  const obtainVoyageAttributeUpdateInitialValue = (): number => {
    switch (voyageAttributeToModify) {
      case EVoyageAttribute.STATEROOM_FORECAST:
        return statsData?.stateroom_forecast ?? 0
      case EVoyageAttribute.PENETRATION_GOAL:
        return statsData?.penetration_goal ?? 0
      default:
        return 0
    }
  }

  const obtainVoyageAttributeUpdateTitle = (): string => {
    switch (voyageAttributeToModify) {
      case EVoyageAttribute.VOYAGE_TARGET:
        return selectedTab === EStatsTab.MONTHLY ? 'Monthly Target' : 'Voyage Target'
      case EVoyageAttribute.STATEROOM_FORECAST:
        return selectedTab === EStatsTab.MONTHLY
          ? 'Monthly Stateroom Forecast'
          : 'Stateroom Forecast'
      case EVoyageAttribute.PENETRATION_GOAL:
        return selectedTab === EStatsTab.MONTHLY ? 'Monthly Penetration Goal' : 'Penetration Goal'
      default:
        return ''
    }
  }

  const obtainVoyageAttributeUpdateLabel = (): string => {
    switch (voyageAttributeToModify) {
      case EVoyageAttribute.VOYAGE_TARGET:
        return 'Target'
      case EVoyageAttribute.STATEROOM_FORECAST:
        return 'Forecast'
      case EVoyageAttribute.PENETRATION_GOAL:
        return 'Penetration Goal'
      default:
        return ''
    }
  }

  const onSelectBox = (box: string) => {
    setSelectedBox(box)

    let formattedCardsData =
      statsData?.cardStats?.map((item) => ({
        guestName: `${item.person_first_name} ${item.person_last_name}`,
        stateroom: item.stateroom_number,
        agentName: `${item.agent_first_name} ${item.agent_last_name}`,
        agentId: item.agent_id,
        portName: item.port_name,
        date: new Date(item.date),
        cardId: item.card_id,
        cardStatus: item.card_status,
        cardTransferredToFolio: item.transferred_to_folio_account,
      })) ?? []

    if (box === EStatusCard.APPROVED || box === EStatusCard.DENIED || box === EStatusCard.PENDING) {
      formattedCardsData = formattedCardsData.filter(
        (card) => card.cardStatus === box.toUpperCase(),
      )
    } else if (box === 'transferred') {
      formattedCardsData = formattedCardsData.filter((card) => card.cardTransferredToFolio)
    }

    setCardStats(formattedCardsData)
  }

  useEffect(() => {
    if (location && location.state && location.state.errorMessage) {
      Notify.error(location.state.errorMessage)
      // Clean state to avoid display error message if user refresh page
      navigate(location.pathname, { replace: true })
    }
  }, [location])

  const formattedData =
    statsData?.stats.map((item) => ({
      fullName: `${item.first_name} ${item.last_name}`,
      appointments: item.appointments,
      inOfficeBookings: item.in_office_bookings,
      bookingForms: item.booking_forms,
      appBookings: item.app_bookings,
      conversionByInteractions: item.conversion_by_interactions,
      inOfficeInteractions: item.in_office_interactions,
      totalBookings: item.total_bookings,
      totalInteractions: item.total_interactions,
      avgAppointmentTime: item.avg_appointment_time,
      sales: item.sales,
      conversion: item.conversion,
      quotes: item.quotes,
      userId: item.user_id,
      cards: item.cards,
      cardsApproved: item.cards_approved,
      cardsDenied: item.cards_denied,
      cardsPending: item.cards_pending,
      cardsTransferredToFolio: item.cards_transferred_to_folio,
      bookingsOffered: item.bookings_offered,
      bookingsConfirmed: item.bookings_confirmed,
      bookingsCanceled: item.bookings_cancelled,
      supplementalBookings: item.supplemental_bookings,
      personalContribution: item.personal_contribution,
      agentStats: item.daily.map((dailyData: IDailyResponse) => ({
        date: new Date(dailyData.dateString),
        port: dailyData.port,
        appointments: dailyData.appointments_daily,
        inOfficeBookings: dailyData.in_office_bookings_daily,
        bookingForms: dailyData.booking_forms_daily,
        appBookings: dailyData.app_bookings_daily,
        inOfficeInteractions: dailyData.in_office_interactions_daily,
        totalBookings: dailyData.total_bookings_daily,
        totalInteractions: dailyData.total_interactions_daily,
        avgAppointmentTime: dailyData.avg_appointment_time_daily,
        sales: dailyData.sales_daily,
        conversionByInteractions: dailyData.conversion_by_interactions_daily,
        conversion: dailyData.conversion_daily,
        quotes: dailyData.quotes_daily,
        cards: dailyData.cards_daily,
        cardsApproved: dailyData.cards_approved_daily,
        cardsDenied: dailyData.cards_denied_daily,
        cardsTransferredToFolio: dailyData.cards_transferred_to_folio_daily,
        cardsPending: dailyData.cards_pending_daily,
        bookingsOffered: dailyData.bookings_offered_daily,
        bookingsConfirmed: dailyData.bookings_confirmed_daily,
        bookingsCanceled: dailyData.bookings_cancelled_daily,
        supplementalBookings: dailyData.supplemental_bookings_daily,
        personalContribution: dailyData.personal_contribution_daily,
      })),
    })) ?? []

  const formattedMonthlyData =
    monthlyStatsData?.stats.map((item) => ({
      cruiseId: item.cruise_id,
      destination: item.destination,
      startPort: item.start_port,
      startDate: item.start_date,
      endPort: item.end_port,
      endDate: item.end_date,
      rdss: item.rdss,
      nights: item.nights,
      charter: item.charter,
      stateroomForecast: item.stateroom_forecast,
      bookingGoalForecast: item.booking_goal_forecast,
      penetrationGoal: item.penetration_goal,
      actualPenetration: item.actual_penetration,
      totalBookings: item.total_bookings,
      monthTracking: item.month_tracking,
      active: item.active,
      description: item.description,
      voyageTarget: item.voyage_target,
      agentStats: item.agents.map((agent: IAgentResponse) => ({
        fullName: `${agent.first_name} ${agent.last_name}`,
        appointments: agent.appointments,
        inOfficeBookings: agent.in_office_bookings,
        bookingForms: agent.booking_forms,
        appBookings: agent.app_bookings,
        inOfficeInteractions: agent.in_office_interactions,
        totalBookings: agent.total_bookings,
        totalInteractions: agent.total_interactions,
        avgAppointmentTime: agent.avg_appointment_time,
        conversionByInteractions: agent.conversion_by_interactions,
        sales: agent.sales,
        conversion: agent.conversion,
        quotes: agent.quotes,
        userId: agent.user_id,
        bookingsOffered: agent.bookings_offered,
        bookingsConfirmed: agent.bookings_confirmed,
        bookingsCanceled: agent.bookings_cancelled,
        supplementalBookings: agent.supplemental_bookings,
        personalContribution: agent.personal_contribution,
        agentStats: undefined,
      })),
    })) ?? []

  useEffect(() => {
    onSelectBox(selectedBox)
  }, [statsData])

  const dailyInfoAvailable =
    statsData && statsData?.stats[0]?.daily && statsData?.stats[0]?.daily.length > 0

  const totalConfirmedBooking =
    statsData?.stats.find((agent) => agent.user_id === 'total')?.bookings_confirmed ?? 0

  const getTotalCardsByStatus = (status?: string): number => {
    let totals: IAgentResponse | null
    if (isAgent) {
      totals =
        statsData && statsData.stats && statsData.stats.length > 0
          ? statsData.stats.find((agent) => agent.user_id === loggedUser?.userId)!
          : null
    } else {
      totals =
        statsData && statsData.stats && statsData.stats.length > 0
          ? statsData.stats[statsData.stats.length - 1]
          : null
    }
    let value = 0
    switch (status) {
      case EStatusCard.APPROVED:
        value = totals ? totals.cards_approved : 0
        break
      case EStatusCard.PENDING:
        value = totals ? totals.cards_pending : 0
        break
      case EStatusCard.DENIED:
        value = totals ? totals.cards_denied : 0
        break
      case 'total':
        value = totals ? totals.cards : 0
        break
      default:
        value = totals ? totals.cards_transferred_to_folio : 0
        break
    }

    return value
  }

  const calculateForecastBookingGoal = (): number => {
    if (statsData && statsData.penetration_goal && statsData.stateroom_forecast) {
      return (statsData.penetration_goal / 100) * statsData.stateroom_forecast
    }
    return 0
  }

  const calculateActualPenetration = (): number => {
    if (statsData && statsData.stateroom_forecast) {
      return totalConfirmedBooking / statsData.stateroom_forecast
    }
    return 0
  }

  const calculateGoal = (): number => {
    const forecastBookingGoal = calculateForecastBookingGoal()
    if (forecastBookingGoal) {
      return totalConfirmedBooking / forecastBookingGoal
    }
    return 0
  }

  const calculateCardGoal = (): number => {
    const cardGoal = statsData?.target_card_goal || 1
    const totalApplications = getTotalCardsByStatus('total')
    return totalApplications / cardGoal
  }

  const onUpdatedStatusCard = async (response: IGenericResponse, newStatus: string) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    setSelectedBox(newStatus)
    await lazyGetStats()
  }

  const changeCardStatus = async (card: IGuestStats, status: EStatusCard) => {
    await updateCardStatus(
      {
        cardId: card.cardId,
        status,
      },
      onUpdatedStatusCard,
    )
  }

  const onUpdatedTransferredCard = async (response: IGenericResponse, transferred: boolean) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    if (transferred) {
      setSelectedBox('transferred')
    }
    await lazyGetStats()
  }

  const changeCardTransferred = async (card: IGuestStats, transferred: boolean) => {
    await updateCardTransferredToFolio(
      {
        cardId: card.cardId,
        transferredCard: transferred,
      },
      onUpdatedTransferredCard,
    )
  }

  const statsTable =
    statsData && statsData?.stats.length > 1 && dailyInfoAvailable ? (
      <AgentStatsTable stats={formattedData} />
    ) : (
      <Alert severity="info">No stats to display for this voyage.</Alert>
    )

  const statsTableMonthly =
    monthlyStatsData && monthlyStatsData?.stats.length > 0 ? (
      <CruiseStatsTableMonthly stats={formattedMonthlyData} />
    ) : (
      <Alert severity="info">No data has been recorded for the selected month.</Alert>
    )

  const bigNumbers = statsData &&
    !isLoading &&
    statsData.stats.length > 0 &&
    dailyInfoAvailable && (
      <Stack
        direction="row"
        justifyContent="space-between"
        columnGap={2}
        marginBottom={2}
        padding={1}
        overflow="auto">
        <BigNumber
          key="big-number-1"
          width="16%"
          label={
            selectedTab === EStatsTab.VOYAGE ? 'STATEROOM FORECAST' : 'MONTHLY STATEROOM FORECAST'
          }
          formattedNumber={
            statsData?.stateroom_forecast
              ? numeral(statsData?.stateroom_forecast).format('0')
              : 'N/A'
          }
          onEdit={
            !isAgent
              ? () => setVoyageAttributeToModify(EVoyageAttribute.STATEROOM_FORECAST)
              : undefined
          }
          style={{ background: theme.palette.secondary.main }}
          color="#FFF"
        />
        <BigNumber
          key="big-number-3"
          width="16%"
          label={selectedTab === EStatsTab.VOYAGE ? 'PENETRATION GOAL' : 'MONTHLY PENETRATION GOAL'}
          formattedNumber={
            statsData.penetration_goal
              ? `${numeral(statsData.penetration_goal).format('0.00')}%`
              : 'N/A'
          }
          style={{ background: theme.palette.secondary.main }}
          color="#FFF"
          onEdit={
            !isAgent
              ? () => setVoyageAttributeToModify(EVoyageAttribute.PENETRATION_GOAL)
              : undefined
          }
        />
        <BigNumber
          key="big-number-2"
          labelFontSize={selectedTab === EStatsTab.MONTHLY ? 14 : 12}
          labelLineHeight="24.5px"
          width="16%"
          label={
            selectedTab === EStatsTab.VOYAGE
              ? 'FORECAST BOOKING GOAL'
              : 'MONTHLY FORECAST BOOKING GOAL'
          }
          formattedNumber={numeral(calculateForecastBookingGoal()).format('0')}
          style={{ background: theme.palette.secondary.main }}
          color="#FFF"
        />
        <BigNumber
          key="big-number-4"
          width="16%"
          label={
            selectedTab === EStatsTab.VOYAGE ? 'ACTUAL PENETRATION' : 'MONTHLY ACTUAL PENETRATION'
          }
          formattedNumber={numeral(calculateActualPenetration()).format('0,0.00%')}
          style={{ background: theme.palette.custom.rainforest }}
          color="#FFF"
        />
        <BigNumber
          key="big-number-5"
          width="16%"
          label={
            selectedTab === EStatsTab.VOYAGE
              ? 'TOTAL BOOKINGS CONFIRMED'
              : 'MONTHLY TOTAL BOOKINGS CONFIRMED'
          }
          formattedNumber={numeral(totalConfirmedBooking).format('0')}
          style={{ background: theme.palette.custom.rainforest }}
          color="#FFF"
        />
        <BigNumber
          key="big-number-6"
          width="16%"
          label={selectedTab === EStatsTab.VOYAGE ? '% TO GOAL' : 'MONTHLY % TO GOAL'}
          formattedNumber={numeral(calculateGoal()).format('0,0.00%')}
          style={{ background: theme.palette.custom.rainforest }}
          color="#FFF"
        />
      </Stack>
    )

  const cardsBigNumbers = statsData &&
    !isLoading &&
    statsData.stats.length > 0 &&
    dailyInfoAvailable && (
      <Stack
        direction="row"
        justifyContent="flex-start"
        columnGap={2}
        marginBottom={2}
        padding={1}
        overflow="auto">
        <BigNumber
          key="big-number-10"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === 'offered'
                ? theme.palette.secondary.light
                : theme.palette.secondary.main,
          }}
          color="#fff"
          label="TOTAL APPLICATIONS"
          onSelectedBox={() => onSelectBox('offered')}
          formattedNumber={numeral(getTotalCardsByStatus('total')).format('0')}
        />
        <BigNumber
          key="big-number-7"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === EStatusCard.APPROVED
                ? theme.palette.secondary.light
                : theme.palette.secondary.main,
          }}
          color="#fff"
          label="APPROVED"
          onSelectedBox={() => onSelectBox(EStatusCard.APPROVED)}
          formattedNumber={numeral(getTotalCardsByStatus(EStatusCard.APPROVED)).format('0')}
        />
        <BigNumber
          key="big-number-8"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === EStatusCard.PENDING
                ? theme.palette.secondary.light
                : theme.palette.secondary.main,
          }}
          color="#fff"
          label="PENDING"
          onSelectedBox={() => onSelectBox(EStatusCard.PENDING)}
          formattedNumber={numeral(getTotalCardsByStatus(EStatusCard.PENDING)).format('0')}
        />
        <BigNumber
          key="big-number-9"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === EStatusCard.DENIED
                ? theme.palette.secondary.light
                : theme.palette.secondary.main,
          }}
          color="#fff"
          label="DENIED"
          onSelectedBox={() => onSelectBox(EStatusCard.DENIED)}
          formattedNumber={numeral(getTotalCardsByStatus(EStatusCard.DENIED)).format('0')}
        />
        <BigNumber
          key="big-number-11"
          style={{
            width: '16%',
            cursor: 'pointer',
            background:
              selectedBox === 'transferred'
                ? theme.palette.secondary.light
                : theme.palette.secondary.main,
          }}
          color="#fff"
          label="TRANSFERRED TO FOLIO"
          onSelectedBox={() => onSelectBox('transferred')}
          formattedNumber={numeral(getTotalCardsByStatus('transferred')).format('0')}
        />
        <BigNumber
          key="big-number-12"
          width="16%"
          label="CC GOAL"
          formattedNumber={numeral(statsData.target_card_goal).format('0')}
          style={{ background: theme.palette.custom.rainforest }}
          color="#FFF"
        />
        <BigNumber
          key="big-number-13"
          width="16%"
          label="% TO GOAL"
          formattedNumber={numeral(calculateCardGoal()).format('0,0.00%')}
          style={{ background: theme.palette.custom.rainforest }}
          color="#FFF"
        />
      </Stack>
    )

  let cardStatsTable

  if (cardStats && cardStats.length > 0) {
    switch (selectedBox) {
      case 'transferred':
      case EStatusCard.APPROVED:
      case EStatusCard.DENIED:
      case EStatusCard.PENDING:
        cardStatsTable = (
          <CardStatsByGuestTable
            stats={cardStats}
            handleOnChangeStatus={changeCardStatus}
            handleOnTransferred={changeCardTransferred}
          />
        )
        break
      default:
        cardStatsTable = statsData && statsData?.stats.length > 0 && dailyInfoAvailable && (
          <CardStatsByAgentTable stats={formattedData} />
        )
        break
    }
  } else {
    cardStatsTable = <Alert severity="info">No stats to display.</Alert>
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
      <Wizard />
      {isActiveCruise && (
        <>
          <TabContext value={selectedTab}>
            <TabList
              sx={tabContainerStyle}
              onChange={(_event: React.SyntheticEvent, newTab: EStatsTab) => {
                setSelectedTab(newTab)
              }}>
              <Tab label="Current Voyage" value={EStatsTab.VOYAGE} />
              <Tab label="Monthly" value={EStatsTab.MONTHLY} />
              <Tab label="Co-brand Credit Card" value={EStatsTab.CARDS} />
            </TabList>
            <Paper elevation={5}>
              <TabPanel value={EStatsTab.VOYAGE}>
                {bigNumbers}
                {isLoading ? <GridSpinner /> : statsTable}
              </TabPanel>
            </Paper>
            <Paper elevation={5}>
              <TabPanel value={EStatsTab.MONTHLY}>
                <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                  <MonthYearSelector
                    initialValue={selectedDate || currentDate}
                    onChange={(values: IStatsPayload) => setSelectedDate(values)}
                  />
                </Box>
                {bigNumbers}
                {isLoading ? <GridSpinner /> : statsTableMonthly}
              </TabPanel>
            </Paper>
            <Paper elevation={5}>
              <TabPanel value={EStatsTab.CARDS}>
                {cardsBigNumbers}
                {isLoading ? <GridSpinner /> : cardStatsTable}
              </TabPanel>
            </Paper>
          </TabContext>
          <UpdateVoyageAttributeForm
            key={`target-dialog-${voyageAttributeToModify}`}
            isOpen={voyageAttributeToModify !== EVoyageAttribute.NONE}
            isLoading={isVoyageAttributeToModifyLoading}
            initialValue={obtainVoyageAttributeUpdateInitialValue()}
            onAccept={
              selectedTab === EStatsTab.VOYAGE ? onAcceptAttribute : onAcceptMonthlyVoyageAttribute
            }
            title={obtainVoyageAttributeUpdateTitle()}
            onReject={() => {
              setVoyageAttributeToModify(EVoyageAttribute.NONE)
            }}
            label={obtainVoyageAttributeUpdateLabel()}
          />
        </>
      )}
    </Container>
  )
}

export default Dashboard
