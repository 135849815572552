import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import { Card, CardContent, DialogActions, Divider, Typography } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { validationSchema } from './validation'
import { IAppointmentOtherForm, IOtherForm } from './types'
import { EOtherResult } from '../../types/appointments'

const resultArray = [
  { id: 1, name: 'Not Related', value: EOtherResult.NOTRELATED },
  { id: 2, name: 'Existing Booking', value: EOtherResult.EXISTINGBOOKING },
  {
    id: 3,
    name: 'Credit Card Question / Application',
    value: EOtherResult.CREDIT_CARD_QUESTION_APPLICATION,
  },
  {
    id: 4,
    name: 'NextCruise Program Question',
    value: EOtherResult.NEXTCRUISE_PROGRAM,
  },
  { id: 5, name: 'General Information', value: EOtherResult.GENERAL_INFORMATION },
  { id: 6, name: 'Loyalty Question', value: EOtherResult.LOYALTY_QUESTION },
  { id: 7, name: 'Complaint', value: EOtherResult.COMPLAINT },
]
const AppointmentOtherForm: React.FC<IAppointmentOtherForm> = (props) => {
  const { isOpen, onClose, onSubmit, isLoading } = props
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<IOtherForm>({
    resolver: yupResolver(validationSchema),
  })

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography> Add a Other </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ margin: '1rem 0' }} />
          <Card>
            <CardContent>
              <Stack>
                <Controller
                  control={control}
                  defaultValue=""
                  name="result"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '50%', mb: 3 }}
                      select
                      size="small"
                      label="Result"
                      error={!!errors.result}
                      helperText={errors?.result?.message as string}>
                      {resultArray.map((item) => (
                        <MenuItem key={`result-${item.id}`} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <TextField
                  id="comment"
                  multiline
                  rows={3}
                  label="Comment"
                  sx={{ width: '100%' }}
                  {...register('comment')}
                />
              </Stack>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            columnGap={1}
            sx={{ mb: '1.25rem', mr: 2 }}>
            <LoadingButton type="submit" loading={isLoading} color="success" variant="contained">
              Save
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
export default AppointmentOtherForm
