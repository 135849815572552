import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  callback_date: yup.string().required('Date is required').typeError('Date is required'),
  callback_time: yup
    .string()
    .required('Hour is required')
    .typeError('Hour is required')
    .matches(
      /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i,
      'Invalid Hour format. Please use 12HR format',
    ),
  agent_id: yup.string().required('Agent is required').typeError('Agent is required'),
})
