import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  room: yup
    .number()
    .required('Stateroom is required')
    .positive('Stateroom must be greater than zero')
    .typeError('Stateroom must be a number'),
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
})

export const callbackValidationSchema = yup.object().shape({
  room: yup
    .number()
    .required('Stateroom is required')
    .positive('Stateroom must be greater than zero')
    .typeError('Stateroom must be a number'),
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  callback_date: yup
    .date()
    .required('Callback date is required')
    .typeError('A valid date is required'),
  callback_time: yup
    .string()
    .required('Callback time is required')
    .matches(
      /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i,
      'Invalid time format. Please use 12HR format',
    ),
})
