import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'
import { IAppointment } from '../../types/appointments'

const LastActionColumn: React.FC<{ appointment: IAppointment }> = ({ appointment }) => {
  const timezone =
    appointment.port && appointment.port.timezone ? appointment.port.timezone : moment.tz.guess()
  const lastActionDate =
    moment.utc(appointment.recorded_on).format('MMM Do ') +
    moment.utc(appointment.recorded_on).format('h:mm A ') +
    moment().tz(timezone).format('(z)')

  return (
    <>
      <Typography variant="body2">{lastActionDate}</Typography>
      {appointment.last_action_comment}
    </>
  )
}

export default LastActionColumn
