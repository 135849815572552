export interface ICruiseAgent {
  id: string
  first_name: string
  last_name: string
  code: string
}

export interface IAgentPayload {
  userId: string
  cruiseId: number
  code: string
}

export interface IDeleteAgentPayload {
  userId: string
  cruiseId: number
}

export interface IUserPayload {
  id?: string
  first_name: string
  last_name: string
  username: string
  role: string
  active: boolean
  password?: string
}

export interface IGetUsersResponse {
  rows: IUserPayload[]
  total_rows: number
}

export interface IChangePasswordPayload {
  id: string
  password: string
}

export enum EUserRole {
  AGENT = 'agent',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superadmin',
}
