import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Chip,
} from '@mui/material'
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded'
import MenuIcon from '@mui/icons-material/Menu'

import { doLogout } from '../../redux/features/auth'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { theme } from '../../styles/mui-theme'
import { EUserRole } from '../../types/users'

interface IPages {
  title: string
  path: string
  alwaysShow: boolean
  role: EUserRole[]
}

const pages: IPages[] = [
  {
    title: 'Dashboard',
    path: '/',
    alwaysShow: true,
    role: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN, EUserRole.AGENT],
  },
  {
    title: 'Appointments',
    path: '/appointments',
    alwaysShow: false,
    role: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN, EUserRole.AGENT],
  },
  {
    title: 'Events',
    path: '/events',
    alwaysShow: false,
    role: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN],
  },
  {
    title: 'Configuration',
    path: '/settings',
    alwaysShow: true,
    role: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN],
  },
]

const MainNavigation: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const isActiveCruise = !!useAppSelector((state) => state.auth.activeCruise)
  const location = useLocation()
  const user = useAppSelector((state) => state.auth.loggedUser)

  const pagesForRole = pages.filter((page) => page.role.includes(user?.role!))

  return (
    <Box>
      <AppBar position="fixed">
        <Toolbar
          variant="dense"
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem' }}>
          <Typography
            onClick={() => navigate('/appointments')}
            variant="h6"
            noWrap
            sx={{ display: { xs: 'none', sm: 'flex' }, cursor: 'pointer' }}>
            NextCruise - SalesTrax
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorElNav(event.currentTarget)
              }}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-app-bar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={Boolean(anchorElNav)}
              onClose={() => {
                setAnchorElNav(null)
              }}
              sx={{ display: { xs: 'block', md: 'none' } }}>
              {pagesForRole.map(
                ({ alwaysShow, title, path }, index) =>
                  (alwaysShow || isActiveCruise) && (
                    <MenuItem
                      key={`item-${index}`}
                      onClick={() => {
                        navigate(path)
                        setAnchorElNav(null)
                      }}
                      selected={location.pathname === path}>
                      <Typography textAlign="center">{title}</Typography>
                    </MenuItem>
                  ),
              )}
            </Menu>
          </Box>
          <Typography
            onClick={() => navigate('/appointments')}
            variant="h6"
            noWrap
            sx={{ width: '100%', justifyContent: 'center', display: { xs: 'flex', sm: 'none' } }}>
            NextCruise - SalesTrax
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, columnGap: '1rem' }}>
            {pagesForRole.map(
              ({ alwaysShow, title, path }, index) =>
                (alwaysShow || isActiveCruise) && (
                  <Button
                    key={`item-${index}`}
                    color="inherit"
                    onClick={() => navigate(path)}
                    sx={{
                      color:
                        location.pathname === path
                          ? theme.palette.custom.lightBlue
                          : theme.palette.background.paper,
                      fontWeight: location.pathname === path ? 'bold' : 'normal',
                      '&:hover': {
                        color: theme.palette.custom.lightBlue,
                      },
                      '&::after': {
                        backgroundColor: theme.palette.custom.lightBlue,
                        borderRadius: '1rem',
                        bottom: '-6px',
                        content: location.pathname === path ? '""' : 'initial',
                        height: '5px',
                        position: 'absolute',
                        width: '100%',
                      },
                    }}>
                    {title}
                  </Button>
                ),
            )}
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex', textTransform: 'capitalize' } }}>
            <Typography>{`${user?.firstName} ${user?.lastName} (${
              user?.role === 'admin' ? 'manager' : user?.role
            })`}</Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              color="inherit"
              onClick={() => {
                dispatch(doLogout())
              }}
              sx={{ display: { sm: 'none' } }}>
              <LockPersonRoundedIcon />
            </IconButton>
            <Chip
              icon={<LockPersonRoundedIcon />}
              label="Sign Off"
              color="secondary"
              sx={{ width: 110, display: { xs: 'none', sm: 'flex' } }}
              onClick={() => dispatch(doLogout())}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default MainNavigation
