import { useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useNewVoyage } from './hook'
import BasicDataForm from '../../../../components/VoyageForm/BasicDataForm'
import {
  IBasicDataFormData,
  IItineraryFormData,
  INewVoyageOfficeHours,
  INewVoyagePortInfo,
  IOfficeHoursFormData,
} from '../../../../components/VoyageForm/types'
import {
  ICallBackTimeCruisePayload,
  ICreateCruisePayload,
  INewVoyageData,
  IPortSequenceCruisePayload,
} from '../../../../types/cruises'
import ItineraryForm from '../../../../components/VoyageForm/ItineraryForm'
import OfficeHoursForm from '../../../../components/VoyageForm/OfficeHoursForm'
import notify from '../../../../utils/notify'
import SettingsLayout from '../../../../components/SettingsLayout'
import { useVoyages } from '../hook'

const NewVoyage = () => {
  const { products, vessels, ports, isLoading, create, rdss } = useNewVoyage()
  const { refetch: refetchVoyages } = useVoyages()
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const [formData, setFormData] = useState<INewVoyageData>({
    vessel: 0,
    product: 0,
    name: '',
    rdss: 0,
    voyage_number: 0,
    charter: 0,
    stateroom_forecast: 0,
    penetration_goal: 0,
    target_card_goal: 0,
    embarkPort: 0,
    embarkDate: '',
    embarkTime: '',
    debarkPort: 0,
    debarkDate: '',
    debarkTime: '',
    portSequence: [],
    officeHours: [],
    callbacks: [],
  })

  const onSuccessCreateCruise = () => {
    notify.success('Voyage created successfully')
    refetchVoyages()
    navigate('/settings/voyages')
  }

  const onSubmitBasicDataForm = (data: IBasicDataFormData) => {
    const difference = moment(data.debarkDate).diff(moment(data.embarkDate), 'days')
    setFormData((oldData) => ({
      ...oldData,
      ...data,
    }))

    // Check if it's necessary to change the port sequence depending on whether embark or debark port data changed
    if (
      formData.portSequence.length === 0 ||
      (formData.portSequence.length > 0 &&
        (formData.portSequence[0].date === '' ||
          formData.portSequence[0].port !== data.embarkPort ||
          formData.portSequence[0].startTime !== data.embarkTime ||
          moment(formData.portSequence[0].date).format('MM/DD/YYYY') !==
            moment(data.embarkDate).format('MM/DD/YYYY') ||
          formData.portSequence[formData.portSequence.length - 1].port !== data.debarkPort ||
          formData.portSequence[formData.portSequence.length - 1].startTime !== data.debarkTime ||
          moment(formData.portSequence[formData.portSequence.length - 1].date).format(
            'MM/DD/YYYY',
          ) !== moment(data.debarkDate).format('MM/DD/YYYY')))
    ) {
      const itinerary: INewVoyagePortInfo[] = [
        {
          port: data.embarkPort,
          date: data.embarkDate,
          startTime: data.embarkTime,
          endTime: '',
        },
      ]

      for (let index = 0; index < difference - 1; index += 1) {
        itinerary.push({
          port: NaN,
          date: moment(data.embarkDate)
            .clone()
            .add(index + 1, 'day')
            .toString(),
          startTime: '',
          endTime: '',
          canModify: true,
        })
      }

      itinerary.push({
        port: data.debarkPort,
        date: data.debarkDate,
        startTime: data.debarkTime,
        endTime: '',
      })
      setFormData((oldData) => ({
        ...oldData,
        portSequence: itinerary,
      }))
    }

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitItineraryForm = (data: IItineraryFormData) => {
    const { portSequence } = data
    setFormData((oldData) => ({ ...oldData, portSequence }))

    // Set the office hours data when there is no modified information
    if (
      formData.officeHours.length === 0 ||
      (formData.officeHours.length > 0 && formData.officeHours[0].hours[0].openHour === '')
    ) {
      const officeHours: INewVoyageOfficeHours[] = portSequence.map((port) => ({
        date: port.date,
        port: port.port,
        hours: [
          {
            openHour: '',
            closeHour: '',
          },
        ],
      }))
      setFormData((oldData) => ({ ...oldData!, officeHours }))
    }
    // Set new office hours when ports changed any data
    else if (
      (formData.officeHours.length > 2 &&
        !formData.officeHours.every(
          (officeHour, index) => officeHour.port === portSequence[index].port,
        )) ||
      formData.officeHours.length !== portSequence.length
    ) {
      const officeHours: INewVoyageOfficeHours[] = portSequence.map((port, index) => {
        if (index === 0 && formData.officeHours[0].port === portSequence[0].port) {
          return formData.officeHours[0]
        }

        if (
          index === portSequence.length - 1 &&
          formData.officeHours[formData.officeHours.length - 1].port ===
            portSequence[portSequence.length - 1].port
        ) {
          return formData.officeHours[formData.officeHours.length - 1]
        }

        return {
          date: port.date,
          port: port.port,
          hours: [
            {
              openHour: '',
              closeHour: '',
            },
          ],
        }
      })
      setFormData((oldData) => ({ ...oldData!, officeHours }))
    }

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitOfficeHoursForm = async (data: IOfficeHoursFormData) => {
    const { officeHours } = data
    setFormData((oldData) => ({ ...oldData!, officeHours }))

    const portSequence: IPortSequenceCruisePayload[] = formData.portSequence.map((port) => ({
      port_id: port.port.toString(),
      arrival_date: `${moment(port.date).format('YYYY-MM-DD')} ${moment(
        port.startTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      departure_date: `${moment(port.date).format('YYYY-MM-DD')} ${moment(
        port.endTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
    }))

    const officeHoursPayload: ICallBackTimeCruisePayload[] = []

    officeHours.forEach((hour) => {
      hour.hours.forEach((internalHour) => {
        officeHoursPayload.push({
          date: moment(hour.date).format('YYYY-MM-DD'),
          open_hour: moment(internalHour.openHour, 'HH:mm').format('hh:mm A'),
          close_hour: moment(internalHour.closeHour, 'HH:mm').format('hh:mm A'),
        })
      })
    })

    const payload: ICreateCruisePayload = {
      ship_id: formData.vessel.toString(),
      destination_id: formData.product.toString(),
      description: formData.name,
      rdss_id: formData.rdss,
      voyage_number: formData.voyage_number,
      charter: formData.charter || 0,
      stateroom_forecast: formData.stateroom_forecast,
      penetration_goal: formData.penetration_goal,
      target_card_goal: formData.target_card_goal,
      start_port: formData.embarkPort.toString(),
      end_port: formData.debarkPort.toString(),
      start_date: `${moment(formData.embarkDate).format('YYYY-MM-DD')} ${moment(
        formData.embarkTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      end_date: `${moment(formData.debarkDate).format('YYYY-MM-DD')} ${moment(
        formData.debarkTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      port_sequence: portSequence,
      office_hours: officeHoursPayload,
      callback_times: [],
    }
    await create(payload, onSuccessCreateCruise)
  }

  const goBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const steps = [
    {
      label: 'Enter basic data for voyage',
      content: (
        <BasicDataForm
          products={products ?? []}
          vessels={vessels ?? []}
          ports={ports ?? []}
          rdss={rdss ?? []}
          values={{
            vessel: formData.vessel,
            product: formData.product,
            name: formData.name,
            rdss: formData.rdss,
            voyage_number: formData.voyage_number,
            charter: formData.charter,
            stateroom_forecast: formData.stateroom_forecast,
            penetration_goal: formData.penetration_goal ?? 0,
            target_card_goal: formData.target_card_goal ?? 0,
            embarkPort: formData.embarkPort,
            embarkDate: formData.embarkDate,
            embarkTime: formData.embarkTime,
            debarkPort: formData.debarkPort,
            debarkDate: formData.debarkDate,
            debarkTime: formData.debarkTime,
          }}
          onSubmit={onSubmitBasicDataForm}
        />
      ),
    },
    {
      label: 'Define itinerary',
      content: (
        <ItineraryForm
          ports={ports ?? []}
          itinerary={formData?.portSequence ?? []}
          goBack={goBack}
          onSubmit={onSubmitItineraryForm}
        />
      ),
    },
    {
      label: 'Set daily office hours',
      content: (
        <OfficeHoursForm
          ports={ports ?? []}
          officeHours={formData?.officeHours ?? []}
          isLoading={isLoading}
          onSubmit={onSubmitOfficeHoursForm}
          goBack={goBack}
        />
      ),
    },
  ]

  return (
    <SettingsLayout>
      <Container maxWidth={false}>
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
          New Voyage
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }>
                {step.label}
              </StepLabel>
              <StepContent>{step.content}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Container>
    </SettingsLayout>
  )
}

export default NewVoyage
