import { useState } from 'react'
import moment from 'moment'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import Fab from '@mui/material/Fab'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Link, Paper } from '@mui/material'
import { useMailDrop } from './hook'
import SettingsLayout from '../../../components/SettingsLayout'
import ConfirmDialog from '../../../components/ConfirmDialog'
import Notify from '../../../utils/notify'
import { headTableStyle } from '../../../styles/generic-styles'
import GridSpinner from '../../../components/GridSpinner'
import UploadFileForm from '../../../components/UploadFileForm'
import { IUploadFileForm } from '../../../components/UploadFileForm/types'

const SettingsMailDrop = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isUploadOpen, setIsUploadOpen] = useState(false)
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)

  const [mailDropName, setMailDropName] = useState<string>('')
  const { data, isLoading, refetch, removeMailDrop, createMailDrop } = useMailDrop()

  const onCLick = () => setIsUploadOpen(true)

  const onCreateComplete = (message: string) => {
    Notify.success(message)
    setIsUploadOpen(false)
    setIsLoadingUpload(false)
    refetch()
  }

  const onCreateError = () => {
    setIsUploadOpen(false)
    setIsLoadingUpload(false)
  }

  const onAccept = async (values: IUploadFileForm): Promise<void> => {
    setIsLoadingUpload(true)
    const form = new FormData()
    form.append('name', values.name)
    form.append('date', moment.utc(values.date).format('YYYY-MM-DD'))
    form.append('file', values.file[0])
    await createMailDrop(form, onCreateComplete, onCreateError)
  }

  const onRemove = (fileName: string) => {
    setIsConfirmOpen(true)
    setMailDropName(fileName)
  }

  const onRemovalCompleted = (message: string) => {
    Notify.success(message)
    setIsConfirmOpen(false)
    refetch()
  }

  const onAcceptRemove = async () => {
    await removeMailDrop(mailDropName, onRemovalCompleted, () => setIsConfirmOpen(false))
  }

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Stack direction="row">
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={onCLick}>
            UPLOAD
          </Button>
        </Stack>
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Mail Drop Management
        </Typography>
      </Stack>
      <Paper>
        <Alert severity="info">
          <Link href="/template.csv" download>
            Download this template
          </Link>{' '}
          and fill in to upload new mail drops.
        </Alert>
      </Paper>
      <TableContainer
        sx={{
          maxHeight: 'calc(100vh - 340px)',
          backgroundColor: 'primary.contrastText',
          marginTop: 2,
        }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={headTableStyle}>MAIL DROP NAME</TableCell>
              <TableCell
                sx={{
                  ...headTableStyle,
                  minWidth: 180,
                  width: 180,
                }}>
                DATE
              </TableCell>
              <TableCell
                sx={{
                  ...headTableStyle,
                  minWidth: 60,
                  width: 60,
                }}
                align="center">
                ROOMS
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...headTableStyle,
                  minWidth: 60,
                  width: 60,
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              data?.map((item, index) => (
                <TableRow key={`table-row-mailDrop-${index}`}>
                  <TableCell>{item.marketingInitiative}</TableCell>
                  <TableCell>
                    {moment.utc(item.marketingInitiativeDate).format('MMMM Do, YYYY')}
                  </TableCell>
                  <TableCell align="center">{item.stateRooms.length}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Delete mail Drop">
                      <Fab
                        color="error"
                        size="small"
                        onClick={() => onRemove(item.marketingInitiative)}>
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && <GridSpinner />}
      {!isLoading && data && data?.length <= 0 && (
        <Alert severity="info">No mail drop campaigns in this voyage yet.</Alert>
      )}

      <ConfirmDialog
        title="Delete Mail Drop"
        message="Are you sure you want to remove this mail drop? Please notice this action cannot be undone."
        onAccept={onAcceptRemove}
        isOpen={isConfirmOpen}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
      />
      <UploadFileForm
        key={`form-mailDrop-${isUploadOpen}`}
        title="Upload Mail Drop"
        isOpen={isUploadOpen}
        onReject={() => {
          setIsUploadOpen(false)
        }}
        isLoading={isLoadingUpload}
        onAccept={onAccept}
      />
    </SettingsLayout>
  )
}

export default SettingsMailDrop
