import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useFieldArray, useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { IOfficeHoursFormData, IOfficeHoursFormProps } from './types'
import NestedOfficeHoursForm from './NestedOfficeHoursForm'
import { officeHoursFormValidationSchema } from './validation'
import { headTableStyle } from '../../styles/generic-styles'

const OfficeHoursForm: React.FC<IOfficeHoursFormProps> = ({
  ports,
  officeHours,
  isLoading,
  onSubmit,
  goBack,
}) => {
  const { control, handleSubmit, setValue, formState } = useForm<IOfficeHoursFormData>({
    defaultValues: {
      officeHours,
    },
    resolver: yupResolver(officeHoursFormValidationSchema),
  })

  const { fields } = useFieldArray({
    control,
    name: 'officeHours',
  })

  const setTimes = (index: number, itemIndex: number) => {
    setValue(`officeHours.${index}.hours.${itemIndex}.openHour`, '00:00', { shouldValidate: true })
    setValue(`officeHours.${index}.hours.${itemIndex}.closeHour`, '23:59', { shouldValidate: true })
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <TableContainer sx={{ marginBottom: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={headTableStyle}>
                Date
              </TableCell>
              <TableCell align="center" sx={headTableStyle}>
                Port
              </TableCell>
              <TableCell colSpan={2} align="center" sx={{ ...headTableStyle, paddingRight: '18%' }}>
                Begin - End
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell align="center">{moment(item.date).format('MM/DD/YYYY')}</TableCell>
                <TableCell align="center">
                  {ports.length > 0 && ports.filter((port) => port.port_id === item.port)[0].name}
                </TableCell>
                <NestedOfficeHoursForm
                  control={control}
                  index={index}
                  formState={formState}
                  setTimes={setTimes}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingButton
        loading={isLoading}
        type="submit"
        variant="contained"
        sx={{ alignSelf: 'flex-start', marginRight: 1 }}>
        CONTINUE
      </LoadingButton>
      <Button type="button" onClick={goBack}>
        BACK
      </Button>
    </Box>
  )
}

export default OfficeHoursForm
