const parseUrl = (url: string): URL | undefined => {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl
  } catch (error) {
    return undefined
  }
}

export const extractHostFromUrl = (url: string): string | undefined => {
  const parsedUrl = parseUrl(url)
  return parsedUrl?.hostname
}

export const extractProtocolFromUrl = (url: string): string | undefined => {
  const parsedUrl = parseUrl(url)
  if (!parsedUrl) return undefined
  return parsedUrl.protocol
}

export const extractPortFromUrl = (url: string): string | undefined => {
  const parsedUrl = parseUrl(url)
  if (!parsedUrl) return undefined
  if (parsedUrl.port) return parsedUrl.port

  return extractProtocolFromUrl(url) === 'https:' ? '443' : '80'
}

export const getStatusName = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'In Line'

    case 'INPROGRESS':
      return 'In Progress'

    case 'FINISHED':
      return 'Finalized'

    case 'DELETED':
      return 'Deleted'

    case 'NOSHOW':
      return 'No Show'

    case 'NOTRELATED':
      return 'Not Related'

    case 'EXISTINGBOOKING':
      return 'Existing Booking'

    case 'CREDITCARDQUESTIONAPPLICATION':
      return 'Credit Card Question / Application'

    case 'NEXTCRUISEPROGRAM':
      return 'Next Cruise Program'

    case 'LOYALTYQUESTION':
      return 'Loyalty Question'

    case 'GENERALINFORMATION':
      return 'General Information'

    case 'COMPLAINT':
      return 'Complaint'

    case 'DRAFT':
      return 'Draft'

    case 'DONE':
      return 'Done'

    case 'OFFERED':
      return 'Offered'

    case 'CONFIRMED':
      return 'Confirmed'

    case 'CANCELLED':
      return 'Cancelled'

    case 'GENERALINFO':
      return 'General Info'

    case 'GENERALFUTURECRUISEQUESTION':
      return 'General Future Cruise Question'

    case 'CAPTAINCLUBQUESTION':
      return 'Captain Club Question'

    case 'SHOREEXCURSIONQUESTION':
      return 'Shore Excursion Question'

    case 'INTERNETISSUEORQUESTION':
      return 'Internet Issue / Question'

    default:
      return ''
  }
}

export const decodeJWT = (token?: string): object | undefined => {
  if (!token) return undefined

  const chunks = token.split('.')
  if (chunks.length !== 3) {
    console.error('JWT token invalid.')
    return undefined
  }
  const dataChunk = chunks[1]

  try {
    const objectAsString = atob(dataChunk).toString()
    const decodedObject = JSON.parse(objectAsString)
    return decodedObject
  } catch (e) {
    console.error('Cannot parse decoded data from JWT token.')
  }

  return undefined
}

export const getCurrencySymbolByName = (currency?: string): string => {
  if (!currency) return '$'

  switch (currency) {
    case 'Euro':
      return '€'
    case 'GBP':
      return '£'
    default:
      return '$'
  }
}
