import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './baseQuery'
import {
  IDeleteMailDropPayload,
  IGetMailDropResponse,
  IMailDrop,
  IMaildropPayload,
} from '../../types/mailDrop'
import { IGenericResponse } from '../../types/generic'
import { IUploadFileForm } from '../../components/UploadFileForm/types'

export const mailDropApi = createApi({
  reducerPath: 'mailDropApi',
  baseQuery,
  endpoints: (builder) => ({
    get: builder.query<IGetMailDropResponse[], number>({
      query: (id) => ({
        url: `/maildrop/${id}`,
        method: 'GET',
      }),
    }),
    remove: builder.mutation<IGenericResponse, IDeleteMailDropPayload>({
      query: (payload) => ({
        url: `/maildrop`,
        method: 'DELETE',
        body: payload,
      }),
    }),
    create: builder.mutation<IGenericResponse, IUploadFileForm>({
      query: (payload) => ({
        url: `/maildrop`,
        method: 'POST',
        body: payload,
      }),
    }),
    getMailDropByRoom: builder.query<IMailDrop[], IMaildropPayload>({
      query: ({ cruiseId, stateRoom }) => ({
        url: `/maildrop/${cruiseId}/${stateRoom}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetQuery, useRemoveMutation, useCreateMutation, useLazyGetMailDropByRoomQuery } =
  mailDropApi
