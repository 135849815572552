import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Alert from '@mui/material/Alert'
import DialogActions from '@mui/material/DialogActions/'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import CloseIcon from '@mui/icons-material/Close'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import { Controller, useForm } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { callbackValidationSchema, validationSchema } from './validation'
import { EAppointmentType, IAppointmentFormProps } from './type'
import GuestsTable from './GuestsTable'
import { IPersonsByRoomResponse } from '../../types/persons'
import { minDateCallback, maxDateCallback } from '../../utils/date'
import { IAppointment } from '../../types/appointments'

const AppointmentForm = (props: IAppointmentFormProps) => {
  const [rowSelected, setRowSelected] = useState<number>()
  const [selectDate, setSelectDate] = useState('')
  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')
  const [selectedPerson, setSelectedPerson] = useState<IPersonsByRoomResponse>()
  const [isAddUser, setIsAddUser] = useState(false)

  const {
    isOpen,
    onReject,
    onAccept,
    persons,
    onLookup,
    isOnlyCallbacks,
    isLoading,
    cruiseDates,
    onChangeCallbackDate,
    onChangeAppointmentType,
    appointmentType,
    assignedAgents,
  } = props

  useEffect(() => {
    if (cruiseDates) {
      setMinDate(minDateCallback(cruiseDates.start_date))
      setMaxDate(maxDateCallback(cruiseDates.end_date))
    }
  }, [cruiseDates])

  const changeRadio = (id: number) => setRowSelected(id)

  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    setValue,
    control,
    reset,
    getValues,
  } = useForm<IAppointment>({
    resolver: yupResolver(
      appointmentType === EAppointmentType.CALLBACK ? callbackValidationSchema : validationSchema,
    ),
  })

  const onSelectPerson = (person: IPersonsByRoomResponse) => {
    setSelectedPerson(person)
    setValue('first_name', person.first_name, {
      shouldValidate: true,
    })
    setValue('last_name', person.last_name, {
      shouldValidate: true,
    })
    setIsAddUser(false)
  }
  const onAddUser = () => {
    const formValues = getValues()
    reset({
      ...formValues,
      first_name: '',
      last_name: '',
    })
    setRowSelected(undefined)
    setSelectedPerson(undefined)
    setIsAddUser(true)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAppointmentType((event.target as HTMLInputElement).value)
  }

  const onSelectDate = (date: string | null) => {
    if (date) {
      onChangeCallbackDate(moment.utc(date).format('YYYY-MM-DD'))
    }
  }
  const showCalendar =
    (appointmentType === EAppointmentType.CALLBACK ||
      appointmentType === EAppointmentType.APPOINTMENT) &&
    !!persons

  const showButtons =
    (!!persons && persons.length === 0) || (!!persons && !!selectedPerson) || isAddUser

  const showTextFields = persons?.length === 0 || isAddUser

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate sx={{ minWidth: 515 }}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            New Appointment
            <IconButton onClick={onReject}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup
              row
              name="type"
              defaultValue={
                isOnlyCallbacks ? EAppointmentType.CALLBACK : EAppointmentType.GET_IN_LINE
              }
              onChange={handleChange}>
              {!isOnlyCallbacks && (
                <FormControlLabel
                  value={EAppointmentType.GET_IN_LINE}
                  control={<Radio />}
                  label="Get in Line"
                />
              )}
              <FormControlLabel
                value={EAppointmentType.CALLBACK}
                control={<Radio />}
                label="Callback"
              />
            </RadioGroup>
          </FormControl>
          <Stack
            direction="row"
            alignItems="flex-start"
            gap={1}
            justifyContent="space-between"
            paddingY={1}>
            <TextField
              type="text"
              fullWidth
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  onLookup(getValues('room')!, trigger)
                }
              }}
              id="room"
              size="small"
              label="Stateroom"
              error={!!errors.room}
              helperText={errors?.room?.message as string}
              {...register('room', { required: true })}
            />

            <LoadingButton
              type="button"
              loading={isLoading}
              onClick={() => onLookup(getValues('room')!, trigger)}
              variant="contained">
              Lookup
            </LoadingButton>
          </Stack>
          {!!persons && persons.length > 0 && (
            <GuestsTable
              persons={persons}
              onSelectPerson={onSelectPerson}
              onAddUser={onAddUser}
              rowSelected={rowSelected}
              changeRadio={changeRadio}
              isAddUser={isAddUser}
            />
          )}
          {showTextFields && (
            <>
              {persons?.length === 0 && (
                <Alert severity="warning">
                  Guest information cannot be retrieved. Please validate manually.
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    autoFocus
                    margin="normal"
                    fullWidth
                    id="first_name"
                    label="First Name"
                    error={!!errors.first_name}
                    helperText={errors?.first_name?.message as string}
                    {...register('first_name', { required: true })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    error={!!errors.last_name}
                    helperText={errors?.last_name?.message as string}
                    {...register('last_name', { required: true })}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {showCalendar && (
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="agent_id"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Agent"
                      fullWidth
                      select
                      error={!!errors.agent_id}
                      helperText={errors?.agent_id?.message as string}>
                      {assignedAgents.map((agent) => (
                        <MenuItem key={`agent-${agent.id}`} value={agent.id}>
                          {`${agent.first_name} ${agent.last_name}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="callback_date"
                  render={({ field: { onChange, value, ...field } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Date"
                        minDate={minDate}
                        maxDate={maxDate}
                        value={selectDate}
                        onChange={(newValue) => {
                          onSelectDate(newValue)
                          setSelectDate(newValue || '')
                          onChange(newValue)
                        }}
                        renderDay={(
                          _day: string,
                          _selectedDays: Array<string | null>,
                          pickersDayProps: PickersDayProps<string>,
                        ) => (
                          <PickersDay
                            {...pickersDayProps}
                            sx={{
                              '&.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.Mui-disabled': {
                                color: 'rgba(255, 255, 255, 0.5)',
                              },
                            }}
                          />
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.callback_date}
                            helperText={errors?.callback_date?.message as string}
                          />
                        )}
                        {...field}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="callback_time"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ mt: 0 }}
                      margin="normal"
                      fullWidth
                      label="Hour"
                      error={!!errors.callback_time}
                      helperText={errors?.callback_time?.message as string}
                      placeholder="HH:mm A"
                      onChange={(e) => {
                        if ((e.nativeEvent as InputEvent).inputType === 'deleteContentBackward') {
                          field.onChange(e.target.value)
                        } else {
                          // Remove non-alphanumeric characters
                          const cleanedValue = e.target.value.replace(/[^0-9a-zA-Z]/g, '')

                          // Format the time as hh:mm A
                          const formattedValue = cleanedValue.replace(
                            /(\d{2})(\d{2})([aApPmM]{0,2})/,
                            (match, hours, minutes, AA) => {
                              const upperCaseAA = AA.toUpperCase()
                              return `${hours}:${minutes} ${upperCaseAA}`
                            },
                          )
                          field.onChange(formattedValue)
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {showButtons && (
          <DialogActions>
            <Button onClick={onReject}>Cancel</Button>
            <LoadingButton type="submit" loading={false}>
              Continue
            </LoadingButton>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  )
}

export default AppointmentForm
