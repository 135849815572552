import { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { UseFormTrigger } from 'react-hook-form'
import moment from 'moment'
import CircleIcon from '@mui/icons-material/Circle'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppointment } from './hook'
import AppointmentForm from '../../components/AppointmentForm'
import { IPersonsByRoomResponse } from '../../types/persons'
import { EAppointmentType } from '../../components/AppointmentForm/type'
import Notify from '../../utils/notify'
import AppointmentFilters from '../../components/AppointmentFilters'
import TabsContainer from '../../components/TabsContainer/index'
import { useVoyages } from '../Settings/Voyages/hook'
import { useAppSelector } from '../../redux/hooks'
import { theme } from '../../styles/mui-theme'
import { ITab } from '../../components/TabsContainer/types'
import AppointmentsGrid from '../../components/AppointmentsGrid'
import MoveAppointmentModal from '../../components/AppointmentModal/MoveAppointmentModal'
import { RootState } from '../../redux/store'
import { IGuestForm } from '../../components/GuestForm/types'
import {
  EAppointmentDBType,
  EAppointmentInLineTab,
  EAppointmentMainTab,
  EAppointmentStatus,
  EStatusAppointment,
  IAppointment,
  IAppointmentOtherResponse,
  IAppointmentQueueResponse,
  IMarkAppointmentAsCallBackPayload,
  IAppointmentSimple,
  EAppointmentDetailsTab,
  IAppointmentQuote,
  ISocketGenericResponse,
  ISocketAgentAssignedResponse,
  IGetAppointmentsPayload,
} from '../../types/appointments'
import { QUEUE_ID } from '../../utils/constants'
import { EUserRole, ICruiseAgent } from '../../types/users'
import { IGenericResponse } from '../../types/generic'
import ConfirmDialog from '../../components/ConfirmDialog'
import AppointmentModal from '../../components/AppointmentModal'
import AppointmentOtherForm from '../../components/AppointmentOtherForm'
import { IOtherForm } from '../../components/AppointmentOtherForm/types'
import AppointmentCallbackForm from '../../components/AppointmentCallbackForm'
import { IMarkAppointmentAsCallBackForm } from '../../components/AppointmentCallbackForm/types'
import { IEventByRoomResponse } from '../../types/events'
import QuoteForm from '../../components/QuoteForm'
import { IMailDrop } from '../../types/mailDrop'
import { useNewVoyage } from '../Settings/Voyages/NewVoyage/hook'
import { ICustomQuoteResponse, IQuotePayload } from '../../types/quotes'
import { UseSettings } from '../Settings/Settings/hook'
import { SocketContext } from '../../utils/socket'

const Appointments = () => {
  // Variable to check location and error messages by redirection
  const location = useLocation()
  const navigate = useNavigate()

  /* General variables */
  const activeCruiseId = useAppSelector((state: RootState) => state.auth.activeCruise?.cruise_id)
  const activeCruiseStartDate = useAppSelector(
    (state: RootState) => state.auth.activeCruise?.start_date,
  )
  const activeCruiseEndDate = useAppSelector(
    (state: RootState) => state.auth.activeCruise?.end_date,
  )
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const isAgent = loggedUser?.role === EUserRole.AGENT
  /* Appointment Quote */
  const { products, vessels } = useNewVoyage()
  const socket = useContext(SocketContext)

  /* New Appt Dialog */
  const [isOpenNewAppt, setIsOpenNewAppt] = useState(false)
  const [newApptDateToFetchHours, setNewApptDateToFetchHours] = useState('')
  const [newApptStateroom, setNewApptStateroom] = useState(0)
  const [newApptAgents, setNewApptAgents] = useState<IPersonsByRoomResponse[] | undefined>()

  /* Appt Grid */
  const [isLoadingApptGrid, setIsLoadingApptGrid] = useState(true)
  const [selectedInLineTab, setSelectedInlineTab] = useState(EAppointmentInLineTab.ALL as string)
  const [selectedMainTab, setSelectedMainTab] = useState(EAppointmentMainTab.INLINE as string)
  const [appointmentStatus, setAppointmentStatus] = useState('PENDING')

  /* State for filters */
  const [keyword, setKeyword] = useState('')
  const onChangeKeyword = (newValue: string) => setKeyword(newValue)
  const [hasPendingItems, setHasPendingItems] = useState(false)
  const onChangeHasPendingItems = () => setHasPendingItems((current) => !current)
  const [keywordDate, setKeywordDate] = useState<null | Date>(null)
  const onChangeKeywordDate = (newValue: null | Date) => setKeywordDate(newValue)
  const [parentType, setParentType] = useState('')
  const [childType, setChildType] = useState('')
  const onChangeType = (parent: string, child: string) => {
    setParentType(parent)
    setChildType(child)
  }
  const filtersRef = useRef<IGetAppointmentsPayload | undefined>()
  const setFilterRef = (filters: IGetAppointmentsPayload) => {
    filtersRef.current = filters
  }

  /* Update/Manage Appt Dialog */
  const [isOpenManageAppt, setIsOpenManageAppt] = useState(false)
  const [isThereNewChangesInAppt, setIsThereNewChangesInAppt] = useState(false)
  const [appointmentSelected, setAppointmentSelected] = useState<IAppointment | undefined>()
  const appointmentSelectedRef = useRef(appointmentSelected)
  const setAppointmentSelectedRef = (appt: IAppointment | undefined) => {
    appointmentSelectedRef.current = appt
    setAppointmentSelected(appt)
  }
  const [eventsSelected, setEventSelected] = useState<IEventByRoomResponse[] | undefined>()
  const [maildropSelected, setMaildropSelected] = useState<IMailDrop[] | undefined>()
  const [manageApptTime, setManageApptTime] = useState(0)

  /* Appt No Show Dialog */
  const [isOpenNoShow, setIsOpenNoShow] = useState(false)

  /* Appointment / Callback */
  const [isOpenAppointmentCallbackForm, setIsOpenAppointmentCallbackForm] = useState(false)
  const [isOpenMoveAppointmentModal, setIsOpenMoveAppointmentModal] = useState(false)
  const [isOpenOtherForm, setIsOpenOtherForm] = useState(false)

  const [isOpenConfirmRemoveOtherResult, setIsOpenConfirmRemoveOtherResult] = useState(false)
  const [isOpenConfirmClearPendingItems, setIsOpenConfirmClearPendingItems] = useState(false)
  const [otherId, setIsOtherId] = useState(0)

  const [selectedApptDetailsTab, setSelectedApptDetailsTab] = useState(
    EAppointmentDetailsTab.LOG as string,
  )
  /* Appointment Quote modal */
  const [isOpenAppointmentQuoteModal, setIsOpenAppointmentQuoteModal] = useState(false)
  const [selectedQuote, setSelectedQuote] = useState<IQuotePayload | undefined>()
  const [isOpenEditQuoteModal, setIsOpenEditQuoteModal] = useState(false)

  /* Multiple appointments */
  const [enableMultipleAppointments, setEnableMultipleAppointments] = useState<boolean>(false)
  const [selectedAppointments, setSelectedAppointments] = useState<IAppointment[]>([])
  const [isLoadingAppointment, setIsLoadingAppointment] = useState(false)
  const [isOpenUpdateNoshow, setIsOpenUpdateNoshow] = useState(false)

  /* Redux */
  const {
    addAttachmentToQuote,
    appointmentById,
    appointments,
    assignAppointmentAgent,
    changeAppointmentPort,
    checkAppointmentAsNoShow,
    completePending,
    createAppointmentCallback,
    createAppointmentQueue,
    createNewQuote,
    createOtherResult,
    cruiseDates,
    cruisesData,
    data: agents,
    deleteAppointment,
    downloadQuoteAttachment,
    editQuote,
    events,
    getAppointmentByIdTrigger,
    getAppointments,
    getEventsByRoomTrigger,
    getGuestByRoomTrigger,
    getMaildropByRoomTrigger,
    getUnfilteredAppointments,
    guestsByRoom,
    invokeAppointmentVisitTime,
    isAddingAttachmentToQuote,
    isCreatingSingleQuote,
    isEditingQuote,
    isFetchingAppointments,
    isFetchingEvents,
    isFetchingQuoteAttachment,
    isFetchingMaildrop,
    isFetchingSearchedGuests,
    isFetchingUnfilteredAppointments,
    isLoading,
    isLoadingMarkAppointmentAsCallback,
    isLoadingOtherResult,
    lazyGetAssignedAgents,
    lazyGetDaysNotAvailable,
    lazyOfficeHours,
    maildrop,
    moveAppointmentCallback,
    restore,
    setAppointmentAsCallback,
    unfilteredAppointments,
    updateOtherResult,
    updatePerson,
    updateStatusAppointment,
  } = useAppointment(newApptStateroom, newApptDateToFetchHours)
  const { assignedAgents, isLoadingAssignedAgents, getAssignedAgentsTrigger } = useVoyages()
  const { queueData } = UseSettings()
  const onlyCallback = queueData ? !queueData.status : false
  const [newApptType, setNewApptType] = useState(`${EAppointmentType.GET_IN_LINE}`)

  useEffect(() => {
    if (onlyCallback) {
      setNewApptType(`${EAppointmentType.CALLBACK}`)
    }
  }, [onlyCallback])

  useEffect(() => {
    if (location && location.state && location.state.errorMessage) {
      Notify.error(location.state.errorMessage)
      // Clean state to avoid display error message if user refresh page
      navigate(location.pathname, { replace: true })
    }
  }, [location])

  /* New Appt Dialog */
  useEffect(() => {
    if (agents) setNewApptAgents(agents)
  }, [agents])

  useEffect(() => {
    if (newApptType === EAppointmentType.CALLBACK && activeCruiseId) {
      lazyGetAssignedAgents(activeCruiseId)
      lazyGetDaysNotAvailable()
      getAssignedAgentsTrigger(activeCruiseId)
    }
  }, [newApptType])

  useEffect(() => {
    setEventSelected(events)
  }, [events])

  useEffect(() => {
    setMaildropSelected(maildrop)
  }, [maildrop])

  /* Appt Grid */
  useEffect(() => {
    if (activeCruiseId) {
      setFilterRef({
        cruise_id: activeCruiseId,
        queue_id: QUEUE_ID,
        status: appointmentStatus,
        keywords: keyword,
        has_pending_items: hasPendingItems,
        keywordsDate: keywordDate
          ? encodeURIComponent(moment(keywordDate).format('MM/DD/YYYY'))
          : '',
        filter_type: parentType,
        filter_status: childType,
        agent_id: isAgent ? loggedUser?.userId : '',
      })
      getAppointments({
        cruise_id: activeCruiseId,
        queue_id: QUEUE_ID,
        status: appointmentStatus,
        keywords: keyword,
        agent_id: isAgent ? loggedUser?.userId : '',
        has_pending_items: hasPendingItems,
        keywordsDate: keywordDate
          ? encodeURIComponent(moment(keywordDate).format('MM/DD/YYYY'))
          : '',
        filter_type: parentType,
        filter_status: childType,
      }).unwrap()
    }
  }, [
    activeCruiseId,
    appointmentStatus,
    keyword,
    hasPendingItems,
    keywordDate,
    parentType,
    childType,
  ])

  useEffect(() => {
    if (activeCruiseId) {
      getUnfilteredAppointments({
        cruise_id: activeCruiseId,
        queue_id: QUEUE_ID,
        status: appointmentStatus,
        agent_id: isAgent ? loggedUser?.userId : '',
      }).unwrap()
    }
  }, [activeCruiseId, appointmentStatus])

  useEffect(() => {
    if (appointments) {
      setIsLoadingApptGrid(false)
    }
  }, [appointments])

  useEffect(() => {
    setIsLoadingApptGrid(isFetchingAppointments)
  }, [isFetchingAppointments])

  useEffect(() => {
    if (appointmentById) setAppointmentSelectedRef(appointmentById)
  }, [appointmentById])

  const onSocketNewAppointment = (data: ISocketGenericResponse) => {
    if (data.userId === loggedUser?.userId) return
    Notify.info(data.message)
    if (filtersRef?.current) {
      getAppointments({ ...filtersRef.current })
    }
  }

  const onSocketAssignAgent = (data: ISocketAgentAssignedResponse) => {
    if (data.userId === loggedUser?.userId) return
    if (filtersRef?.current) {
      getAppointments({ ...filtersRef.current })
    }
    if (data.appointmentId === appointmentSelectedRef.current?.appointment_id) {
      if (data.agentId === loggedUser?.userId || loggedUser?.role !== EUserRole.AGENT) {
        Notify.info(data.message)
        getAppointmentByIdTrigger(data.appointmentId)
        return
      }
      setIsOpenManageAppt(false)
    }
    if (
      (loggedUser?.role === EUserRole.AGENT && data.agentId === loggedUser.userId) ||
      loggedUser?.role !== EUserRole.AGENT
    ) {
      Notify.info(data.message)
    }
  }

  const onSocketEditPerson = (data: ISocketAgentAssignedResponse) => {
    if (data.userId === loggedUser?.userId) return
    if (filtersRef?.current) {
      getAppointments({ ...filtersRef.current })
    }
    if (data.appointmentId === appointmentSelectedRef.current?.appointment_id) {
      Notify.info(data.message)
      getAppointmentByIdTrigger(data.appointmentId)
      return
    }
    if (loggedUser?.role !== EUserRole.AGENT) Notify.info(data.message)
  }

  const onSocketDeletedAppointment = (data: ISocketGenericResponse) => {
    if (data.userId === loggedUser?.userId) return
    if (loggedUser?.role !== 'agent') {
      Notify.info(data.message)
    }
    if (filtersRef?.current) {
      getAppointments({ ...filtersRef.current })
    }
    if (data.appointmentId === appointmentSelectedRef.current?.appointment_id) {
      getAppointmentByIdTrigger(data.appointmentId)
      setIsOpenManageAppt(false)
    }
  }

  const onSocketRestoredAppointment = (data: ISocketGenericResponse) => {
    if (data.userId === loggedUser?.userId) return
    if (loggedUser?.role !== 'agent') {
      Notify.info(data.message)
    }
    if (filtersRef?.current) {
      getAppointments({ ...filtersRef.current })
    }
    if (data.appointmentId === appointmentSelectedRef.current?.appointment_id) {
      getAppointmentByIdTrigger(data.appointmentId)
    }
  }

  /* Appt Grid */
  const onSelectAppointment = async (appointment: IAppointment) => {
    setManageApptTime(moment().valueOf())
    await getAppointmentByIdTrigger(appointment.appointment_id!)
    setIsOpenManageAppt(true)
    setIsThereNewChangesInAppt(false)
    await getAssignedAgentsTrigger(activeCruiseId!)
  }

  const onChangeDetailTab = (_event: React.SyntheticEvent, newTab: string) =>
    setSelectedApptDetailsTab(newTab)
  const refreshApptList = (status: string) => {
    const filters = {
      cruise_id: activeCruiseId || 0,
      queue_id: QUEUE_ID,
      status: '',
      keywords: keyword,
      has_pending_items: hasPendingItems,
      keywordsDate: keywordDate ? encodeURIComponent(moment(keywordDate).format('MM/DD/YYYY')) : '',
      filter_type: parentType,
      filter_status: childType,
      agent_id: isAgent ? loggedUser?.userId : '',
    }
    setFilterRef(filters)
    const filtersForCopyList = {
      cruise_id: activeCruiseId || 0,
      queue_id: QUEUE_ID,
      status: '',
      agent_id: isAgent ? loggedUser?.userId : '',
    }
    switch (status) {
      case 'In Line':
        filters.status = EAppointmentStatus.PENDING
        filtersForCopyList.status = EAppointmentStatus.PENDING
        getAppointments(filters).unwrap()
        getUnfilteredAppointments(filtersForCopyList).unwrap()
        break
      case 'In Progress':
        filters.status = EAppointmentStatus.INPROGRESS
        filtersForCopyList.status = EAppointmentStatus.INPROGRESS
        getAppointments(filters).unwrap()
        getUnfilteredAppointments(filtersForCopyList).unwrap()

        break

      case 'Finalized':
        filters.status = EAppointmentStatus.FINISHED
        filtersForCopyList.status = EAppointmentStatus.FINISHED
        getAppointments(filters).unwrap()
        getUnfilteredAppointments(filtersForCopyList).unwrap()
        break

      case 'Deleted Appointments':
        filters.status = EAppointmentStatus.DELETED
        filtersForCopyList.status = EAppointmentStatus.DELETED
        getAppointments(filters).unwrap()
        getUnfilteredAppointments(filtersForCopyList).unwrap()
        break
      default:
        break
    }
  }

  /* New Appt Dialog */
  const onSavedNewAppt = async (response: IAppointmentQueueResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }
    Notify.success(response.message)

    if (activeCruiseId) {
      await getAppointments({
        cruise_id: activeCruiseId,
        queue_id: QUEUE_ID,
        status: EAppointmentStatus.PENDING,
        agent_id: isAgent ? loggedUser?.userId : '',
      })
      setFilterRef({
        cruise_id: activeCruiseId,
        queue_id: QUEUE_ID,
        status: EAppointmentStatus.PENDING,
        agent_id: isAgent ? loggedUser?.userId : '',
      })
    }

    setIsOpenNewAppt(false)
    refreshApptList(EAppointmentMainTab.INLINE)
    setNewApptAgents(undefined)
    setNewApptType('')
    setNewApptStateroom(0)
    setSelectedMainTab(EAppointmentMainTab.INLINE)
  }

  const onAcceptNewAppt = async (values: IAppointment) => {
    if (newApptType === EAppointmentType.CALLBACK) {
      await createAppointmentCallback(
        {
          ...values,
          cruise_id: activeCruiseId,
          callback_date: moment(values.callback_date).format('YYYY-MM-DD'),
          inPersonCallback: false,
          user_logged_id: loggedUser?.userId,
        },
        onSavedNewAppt,
        () => setIsOpenNewAppt(false),
      )
      return
    }

    await createAppointmentQueue(
      { ...values, cruise_id: activeCruiseId, user_logged_id: loggedUser?.userId },
      onSavedNewAppt,
      () => setIsOpenNewAppt(false),
    )
  }

  const handleDeleteOthersResult = async (id: number) => {
    setIsOtherId(id)
    setIsOpenConfirmRemoveOtherResult(true)
  }

  const onSavedOtherResult = async (response: IAppointmentOtherResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }
    setAppointmentSelectedRef({
      ...appointmentSelected,
      logs: response.appointment.logs,
    })
    Notify.success(response.message)
    setIsOpenOtherForm(false)
    setIsThereNewChangesInAppt(true)
    const time = moment().diff(manageApptTime, 'seconds') + (appointmentSelected?.visitTime ?? 0)
    await invokeAppointmentVisitTime({
      id: appointmentSelected?.appointment_id!,
      time,
    })
    await getAppointmentByIdTrigger(appointmentSelected?.appointment_id!)
    setIsLoadingAppointment(false)
  }

  const onAcceptOtherResult = async (values: IOtherForm) => {
    setIsLoadingAppointment(true)
    await createOtherResult(
      { ...values, appointmentId: appointmentSelected?.appointment_id! },
      onSavedOtherResult,
    )
  }

  const onSavedUpdateOtherResult = (response: IAppointmentOtherResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }
    setAppointmentSelectedRef({
      ...appointmentSelected,
      others: response.appointment.others,
    })

    Notify.success(response.message)
    setIsOpenConfirmRemoveOtherResult(false)
    setIsThereNewChangesInAppt(true)
  }

  const onUpdateOther = async () => {
    updateOtherResult(
      {
        appointmentId: appointmentSelected?.appointment_id!,
        otherId,
      },
      onSavedUpdateOtherResult,
    )
    await getAppointmentByIdTrigger(appointmentSelected?.appointment_id!)
  }

  const onLookupNewAppt = async (value: number, trigger: UseFormTrigger<IAppointment>) => {
    const hasValidateInput = await trigger('room')
    if (hasValidateInput) {
      setNewApptStateroom(value)
    }
  }

  const onRejectNewAppt = () => {
    setNewApptAgents(undefined)
    setIsOpenNewAppt(false)
    setNewApptType('')
    setNewApptStateroom(0)
  }

  const onRejectManageAppt = async (apptTime: number) => {
    invokeAppointmentVisitTime({ id: appointmentSelected?.appointment_id!, time: apptTime })
    setIsOpenManageAppt(false)
    setAppointmentSelectedRef(undefined)
    setSelectedApptDetailsTab(EAppointmentDetailsTab.LOG)
    setEventSelected(undefined)
    setMaildropSelected(undefined)
    setSelectedAppointments([])
    setEnableMultipleAppointments(false)

    if (isThereNewChangesInAppt) {
      refreshApptList(selectedMainTab)
    }
  }

  const saveTime = async (apptTime: number) => {
    invokeAppointmentVisitTime({ id: appointmentSelected?.appointment_id!, time: apptTime })
  }

  const onUpdatedStatusAppt = (response: IGenericResponse, status: string) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    setIsThereNewChangesInAppt(true)

    switch (status) {
      case EStatusAppointment.PENDING:
        setAppointmentSelectedRef({ ...appointmentSelected!, status, agent: undefined })
        break
      default:
        setAppointmentSelectedRef({ ...appointmentSelected!, status })
        break
    }
  }

  const onChangeStatusAppt = async (status: EStatusAppointment) => {
    if (
      !appointmentSelected?.agent?.agent_id &&
      appointmentSelected?.status === EStatusAppointment.PENDING
    ) {
      Notify.warning('Please assign an agent!')
      return
    }

    if (
      status === EStatusAppointment.FINISHED &&
      appointmentSelected?.bookings?.length === 0 &&
      appointmentSelected?.quotes?.length === 0 &&
      appointmentSelected?.others?.length === 0 &&
      appointmentSelected?.cards?.length === 0
    ) {
      Notify.warning('Please create booking, a quote, a card or select other result!')
      return
    }

    await updateStatusAppointment(
      { appointmentId: appointmentSelected?.appointment_id!, status },
      onUpdatedStatusAppt,
    )
  }

  const onAssignedAgent = async (response: IGenericResponse, agent: ICruiseAgent) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    setIsThereNewChangesInAppt(true)

    setAppointmentSelectedRef({
      ...appointmentSelected!,
      visitTime: 0,
      agent: {
        first_name: agent?.first_name,
        last_name: agent?.last_name,
        agent_id: agent?.id,
      },
      status: EStatusAppointment.IN_PROGRESS,
    })
  }

  const onSelectAgent = async (agentId: string) => {
    const agent = assignedAgents?.find((agentItem) => agentItem.id === agentId)

    if (agent) {
      await assignAppointmentAgent(
        { appointmentId: appointmentSelected?.appointment_id!, agent },
        onAssignedAgent,
      )
    } else {
      await updateStatusAppointment(
        {
          appointmentId: appointmentSelected?.appointment_id!,
          status: EStatusAppointment.PENDING,
        },
        onUpdatedStatusAppt,
      )
    }
  }

  const onPortUpdated = async (response: IAppointment) => {
    Notify.success('Port Updated successfully.')
    setIsThereNewChangesInAppt(true)
    setAppointmentSelectedRef({ ...appointmentSelected!, port: response.port })
    setIsOpenMoveAppointmentModal(false)
  }

  const onSelectedPort = async (appointmentId?: number, cruisePortId?: number) => {
    if (cruisePortId && appointmentId)
      await changeAppointmentPort({ id: appointmentId, portId: cruisePortId }, onPortUpdated, () =>
        setIsOpenMoveAppointmentModal(false),
      )
  }

  const onAcceptedNoShow = (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    setIsThereNewChangesInAppt(true)
    setIsOpenUpdateNoshow(false)
    setAppointmentSelectedRef({
      ...appointmentSelected!,
      status: EStatusAppointment.FINISHED,
    })
  }

  const onUpdateNoShow = async () => {
    await checkAppointmentAsNoShow(appointmentSelected?.appointment_id!, onAcceptedNoShow)
  }

  const onSocketNoShowAppointment = (data: ISocketGenericResponse) => {
    if (data.userId === loggedUser?.userId) return
    if (data.appointmentId === appointmentSelectedRef.current?.appointment_id)
      setIsOpenManageAppt(false)
    if (filtersRef?.current) {
      getAppointments({ ...filtersRef.current })
    }
    if (loggedUser?.role === EUserRole.AGENT) return
    Notify.info(data.message)
  }

  const onAcceptedUpdatePerson = async (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    if (response.message.toLowerCase().indexOf('no changes') === -1) {
      setIsThereNewChangesInAppt(true)
      setIsLoadingAppointment(false)
      await getAppointmentByIdTrigger(appointmentSelected?.appointment_id!)
      setIsLoadingAppointment(false)
      refreshApptList(selectedMainTab)
    }
  }

  const onRejectedUpdatePerson = (message: string) => {
    Notify.error(message)
  }

  const onUpdatePerson = async (guest: IGuestForm) => {
    if (appointmentSelected) {
      await updatePerson(
        {
          ...guest,
          appointmentId: appointmentSelected.appointment_id!,
          personId: appointmentSelected.person?.person_id!,
        },
        onAcceptedUpdatePerson,
        onRejectedUpdatePerson,
      )
    } else {
      Notify.error('Appointment not selected')
    }
  }

  const onAcceptedDelete = (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    setIsOpenManageAppt(false)
    refreshApptList(selectedMainTab)
  }

  const onRejectedDelete = (error: string) => {
    Notify.error(error)
  }

  const onDeleteAppointment = async () => {
    await deleteAppointment(
      appointmentSelected?.appointment_id!,
      onAcceptedDelete,
      onRejectedDelete,
    )

    setAppointmentSelectedRef({
      ...appointmentSelected,
      isDeleted: 0,
    })
  }

  const onAcceptedRestore = (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    setIsOpenManageAppt(false)
    refreshApptList(selectedMainTab)
  }

  const onRestoreAppointment = async (id: number) => {
    await restore(id, onAcceptedRestore)
  }

  const onOpenAppointmentCallbackForm = async () => {
    setIsOpenAppointmentCallbackForm(true)
    await lazyGetDaysNotAvailable().unwrap()
  }

  const onAppointmentsOrdered = async (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }
    refreshApptList(selectedMainTab)
    Notify.success(response.message)
  }

  const onDragRowEnd = async (appointment: IAppointment, order: number) => {
    const oldPosition = appointments!.rows.indexOf(appointment)
    const newPosition = appointments!.rows.indexOf(appointment) - order
    const orderedAppointments = [...appointments!.rows]
    orderedAppointments.splice(oldPosition, 1)
    orderedAppointments.splice(newPosition, 0, appointment)

    const appointmentsToOrder: IAppointmentSimple[] = orderedAppointments.map((appt, index) => ({
      appointment_id: appt.appointment_id!,
      order: index,
    }))
    setIsLoadingApptGrid(true)
    await moveAppointmentCallback(appointmentsToOrder, onAppointmentsOrdered)
  }

  /* Appointment / Callback */
  const onCompletedSetAppointmentAsCallback = async (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    await getAppointmentByIdTrigger(appointmentSelected?.appointment_id!).unwrap()
    setIsOpenAppointmentCallbackForm(false)
    setIsThereNewChangesInAppt(true)
    Notify.success(response.message)
  }

  const onSubmitAppointmentAsCallback = async (data: IMarkAppointmentAsCallBackForm) => {
    if (lazyOfficeHours?.data.length === 0) {
      Notify.error('No callbacks available for this date')
      return
    }
    const payload: IMarkAppointmentAsCallBackPayload = {
      ...data,
      agent_id: data.agent_id === 'no-agent' ? '' : data.agent_id,
      callback_date: moment.utc(data.callback_date).format('MM/DD/YYYY'),
      appointment_id: appointmentSelected?.appointment_id!,
    }
    await setAppointmentAsCallback(payload, onCompletedSetAppointmentAsCallback)
  }

  const onSocketCallBackAppointment = (data: ISocketGenericResponse) => {
    if (data.userId === loggedUser?.userId) return
    if (data.appointmentId === appointmentSelectedRef.current?.appointment_id)
      setIsOpenManageAppt(false)

    if (filtersRef.current) {
      getAppointments({ ...filtersRef.current })
    }

    const isAssignedUser = loggedUser?.userId !== appointmentSelectedRef?.current?.agent?.agent_id
    if (loggedUser?.role === EUserRole.AGENT && isAssignedUser) return
    Notify.info(data.message)
  }

  /* Tab Containers */
  const onChangeMainTab = (_event: React.SyntheticEvent, newTab: string) => {
    /* Clear all filters */
    setKeyword('')
    setHasPendingItems(false)
    setKeywordDate(null)
    setParentType('')
    setChildType('')

    setSelectedMainTab(newTab)
    switch (newTab) {
      case 'In Line':
        setAppointmentStatus(EAppointmentStatus.PENDING)
        break
      case 'In Progress':
        setAppointmentStatus(EAppointmentStatus.INPROGRESS)
        break
      case 'Finalized':
        setAppointmentStatus(EAppointmentStatus.FINISHED)
        break
      case 'Deleted Appointments':
        setAppointmentStatus(EAppointmentStatus.DELETED)
        break
      default:
        break
    }
  }

  /* Appt No Show Dialog */
  const onSelectNoShowAppointment = (appointment: IAppointment) => {
    setAppointmentSelectedRef(appointment)
    setIsOpenNoShow(true)
  }

  const onCloseNoShowDialog = () => {
    setAppointmentSelectedRef(undefined)
    setIsOpenNoShow(false)
  }

  const onCheckedAppointmentAsNoShow = async (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    Notify.success(response.message)
    refreshApptList(selectedMainTab)
  }

  const onAcceptNoShowAppointment = async () => {
    setIsOpenNoShow(false)
    setIsLoadingApptGrid(true)

    await checkAppointmentAsNoShow(
      appointmentSelected?.appointment_id!,
      onCheckedAppointmentAsNoShow,
    )
  }

  /* Appt clear pending task */
  const onSelectClearPendingTasks = (appointment: IAppointment) => {
    setAppointmentSelected(appointment)
    setIsOpenConfirmClearPendingItems(true)
  }

  const onCloseClearPendingTasks = () => {
    setAppointmentSelected(undefined)
    setIsOpenConfirmClearPendingItems(false)
  }

  const onCompleteClearPendingTask = (response: IGenericResponse) => {
    Notify.success(response.message)
    onCloseClearPendingTasks()
    refreshApptList(selectedMainTab)
  }

  const onAcceptClearPendingTasks = async () => {
    setIsLoadingApptGrid(true)
    await completePending(appointmentSelected?.appointment_id!, onCompleteClearPendingTask)
  }

  /* Active voyage validation */
  const validateActiveVoyageDates = () => {
    const dateNow = moment()
    const voyageStartDate = moment(activeCruiseStartDate, 'YYYY-MM-DD hh:mm:ss')
    const voyageEndDate = moment(activeCruiseEndDate, 'YYYY-MM-DD hh:mm:ss')

    if (dateNow.diff(voyageStartDate) < 0) {
      Notify.error('Sorry, you cant create an appointment right now. The voyage has not started.')
    }
    if (dateNow.diff(voyageEndDate) > 0) {
      Notify.error("Sorry, you can't create an appointment right now. The voyage has ended.")
    }
    if (dateNow.diff(voyageStartDate) >= 0 && dateNow.diff(voyageEndDate) <= 0) {
      setIsOpenNewAppt(true)
    }
  }

  /* Create Quote */
  const onQuoteCreated = async (response: ICustomQuoteResponse, file?: (File | string)[]) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }

    const { quoteId, groupShellNumber } = response
    // Attach file (if exists)
    if (file && file.length > 0) {
      const form = new FormData()
      if (quoteId) form.append('quoteId', `${quoteId}`)
      if (groupShellNumber) form.append('groupShellNumber', `${groupShellNumber}`)
      form.append('file', file[0])
      await addAttachmentToQuote(form, (responseAttachment: IGenericResponse) => {
        Notify.success(response.message)
        Notify.success(responseAttachment.message)
      })
    } else {
      Notify.success(response.message)
    }
  }

  const onSubmitCreateQuote = async (data: IQuotePayload, reset?: boolean) => {
    setIsLoadingAppointment(true)
    const payload: IQuotePayload = {
      ...data,
      agentId: appointmentSelected?.agent?.agent_id!,
      appointmentId: appointmentSelected?.appointment_id!,
      comment: data.comment ?? '',
      nonRefundable: data.nonRefundable ?? false,
      sailDate: data.sailDate !== '' ? data.sailDate : undefined,
      stateroomCategory: data.stateroomCategory ?? '',
      noBookReason: data.noBookReason ?? '',
    }

    await createNewQuote(payload, (response: ICustomQuoteResponse) =>
      onQuoteCreated(response, data.file),
    )

    if (!reset) {
      setIsOpenAppointmentQuoteModal(false)
    }
    const time = moment().diff(manageApptTime, 'seconds') + (appointmentSelected?.visitTime ?? 0)
    await invokeAppointmentVisitTime({
      id: appointmentSelected?.appointment_id!,
      time,
    })
    await getAppointmentByIdTrigger(appointmentSelected?.appointment_id!)
    setIsLoadingAppointment(false)
  }

  /* Edit Quote */
  const openEditQuoteModal = (quote: IAppointmentQuote) => {
    const formattedQuote: IQuotePayload = {
      agentId: quote.agent?.agent_id!,
      amount: quote.quoted_amount,
      appointmentId: quote.appointment_id!,
      comment: quote.quote_comment ?? '',
      destinationId: quote.destination?.id!,
      groupShellNumber: quote.group_shell_number ?? '',
      hasAttachment: quote.has_quote_attachment,
      nonRefundable: quote.quote_non_refundable !== 0,
      quoteId: quote.quote_id,
      sailDate: quote.quoted_sail_date,
      shipId: quote.ship?.id!,
      stateroomCategory: quote.quoted_stateroom_category,
      noBookReason: quote.no_book_reason,
      currency: quote.currency,
      exclusiveOnboardCredit: quote.exclusive_onboard_credit,
      nextCruiseBonus: !!quote.next_cruise_bonus,
      bonusSavingsValue: !!quote.bonus_savings_value,
      bonusValue: quote.bonus_value,
    }
    setIsOpenEditQuoteModal(true)
    setSelectedQuote(formattedQuote)
  }

  const onSubmitEditQuote = async (data: IQuotePayload) => {
    setIsLoadingAppointment(true)
    const payload: IQuotePayload = {
      ...data,
      appointmentId: selectedQuote?.appointmentId!,
      agentId: selectedQuote?.agentId!,
    }
    await editQuote(payload, (response: ICustomQuoteResponse) =>
      onQuoteCreated(response, data.file),
    )
    setIsOpenEditQuoteModal(false)
    setSelectedQuote(undefined)
    const time = moment().diff(manageApptTime, 'seconds') + (appointmentSelected?.visitTime ?? 0)
    await invokeAppointmentVisitTime({
      id: appointmentSelected?.appointment_id!,
      time,
    })
    await getAppointmentByIdTrigger(appointmentSelected?.appointment_id!)
    setIsLoadingAppointment(false)
  }

  /* Multiple appointments */
  const openMultipleAppointments = async () => {
    setManageApptTime(moment().valueOf())
    setIsOpenManageAppt(true)
    setAppointmentSelected(selectedAppointments[0])
    await getAssignedAgentsTrigger(activeCruiseId!)
  }

  const onSelectMultipleAppointments = async (appts: IAppointment[]) => {
    const newSelectedAppointments: IAppointment[] = []
    appts.forEach(async (appt) => {
      const newAppt = await getAppointmentByIdTrigger(appt.appointment_id!).unwrap()
      newSelectedAppointments.push(newAppt)
    })
    setSelectedAppointments(newSelectedAppointments)
  }

  const removeAppointmentFromSelectedAppointments = async (
    appt: IAppointment,
    apptTime: number,
  ) => {
    const index = selectedAppointments.indexOf(appt)
    const newSelectedAppts = [...selectedAppointments]
    newSelectedAppts.splice(index, 1)
    if (appt.appointment_id === appointmentSelected?.appointment_id) {
      setIsLoadingAppointment(true)
      await invokeAppointmentVisitTime({ id: appt.appointment_id!, time: apptTime })
      await getAppointmentByIdTrigger(newSelectedAppts[0].appointment_id!)
      setIsLoadingAppointment(false)
    }
    setSelectedAppointments(newSelectedAppts)
  }

  const changeAppointmentSelected = async (index: number, apptTime: number) => {
    setIsLoadingAppointment(true)
    await invokeAppointmentVisitTime({ id: appointmentSelected?.appointment_id!, time: apptTime })
    await getAppointmentByIdTrigger(selectedAppointments[index].appointment_id!)
    setIsLoadingAppointment(false)
  }

  const handleOnLookingUpStateRoom = async (room: number) => {
    getGuestByRoomTrigger(room)
  }

  const onSocketResultUpdatedOrCreated = (data: ISocketGenericResponse) => {
    if (data.userId === loggedUser?.userId) return
    if (data.appointmentId === appointmentSelectedRef.current?.appointment_id) {
      getAppointmentByIdTrigger(data.appointmentId)
    }

    if (filtersRef.current) {
      getAppointments({ ...filtersRef.current })
    }

    const isAssignedUser = loggedUser?.userId !== appointmentSelectedRef?.current?.agent?.agent_id
    if (loggedUser?.role === EUserRole.AGENT && isAssignedUser) return
    Notify.info(data.message)
  }

  const onSocketChangeStatus = (data: ISocketGenericResponse) => {
    if (data.userId === loggedUser?.userId) return
    if (data.appointmentId === appointmentSelectedRef.current?.appointment_id) {
      getAppointmentByIdTrigger(data.appointmentId)
    }

    if (filtersRef.current) {
      getAppointments({ ...filtersRef.current })
    }

    const isAssignedUser = loggedUser?.userId !== appointmentSelectedRef?.current?.agent?.agent_id
    if (loggedUser?.role === EUserRole.AGENT && isAssignedUser) return
    Notify.info(data.message)
  }

  // listeners for socket
  useEffect(() => {
    socket.on('newAppointment', onSocketNewAppointment)
    socket.on('noShow', onSocketNoShowAppointment)
    socket.on('callback', onSocketCallBackAppointment)
    socket.on('agentAssigned', onSocketAssignAgent)
    socket.on('editPerson', onSocketEditPerson)
    socket.on('resultUpdatedOrCreated', onSocketResultUpdatedOrCreated)
    socket.on('changeStatus', onSocketChangeStatus)
    socket.on('deletedAppointment', onSocketDeletedAppointment)
    socket.on('restoredAppointment', onSocketRestoredAppointment)

    return () => {
      socket.off('newAppointment', onSocketNewAppointment)
      socket.off('noShow', onSocketNoShowAppointment)
      socket.off('callback', onSocketCallBackAppointment)
      socket.off('agentAssigned', onSocketAssignAgent)
      socket.off('editPerson', onSocketEditPerson)
      socket.off('resultUpdatedOrCreated', onSocketResultUpdatedOrCreated)
      socket.off('changeStatus', onSocketChangeStatus)
      socket.off('deletedAppointment', onSocketDeletedAppointment)
      socket.off('restoredAppointment', onSocketRestoredAppointment)
    }
  }, [])

  const inLineContent = (
    <>
      <AppointmentFilters
        appointments={appointments?.rows.filter((item) => item.status === 'PENDING') ?? []}
        displayHasPendingItems={false}
        displayFilterByType={false}
        displayFilterByDate={false}
        displayManageMultiple
        keyword={keyword}
        onChangeKeyword={onChangeKeyword}
        isFetching={isFetchingUnfilteredAppointments}
        onChangeManageMultipleAppointment={setEnableMultipleAppointments}
        enableMultipleAppointments={enableMultipleAppointments}
        showMultipleAppointmentButton={
          enableMultipleAppointments && selectedAppointments.length > 1
        }
        inLineTab
      />
      <TabsContainer
        selectedTab={selectedInLineTab}
        onChangeTab={(_event, newTab) => setSelectedInlineTab(newTab)}
        tabs={[
          {
            title: EAppointmentInLineTab.ALL,
            content: (
              <AppointmentsGrid
                key={`inline-all-table-${enableMultipleAppointments}`}
                appointments={appointments?.rows ?? []}
                isLoading={isLoadingApptGrid}
                onSelectAppointment={onSelectAppointment}
                isInLineGrid
                enableSorting
                onDragRowEnd={onDragRowEnd}
                onSelectNoShowAppointment={onSelectNoShowAppointment}
                enableRowSelection={enableMultipleAppointments}
                onSelectMultipleAppointments={onSelectMultipleAppointments}
                onSelectClearPendingTasks={onSelectClearPendingTasks}
              />
            ),
            badge: appointments?.people.pending.toString() ?? '',
            position: 'left',
            badgeColor: theme.palette.text.disabled,
          },
          {
            title: EAppointmentInLineTab.INLINE,
            content: (
              <AppointmentsGrid
                key={`inline-inline-table-${enableMultipleAppointments}`}
                appointments={
                  appointments?.rows.filter((item) => item.type === EAppointmentDBType.QUEUED) ?? []
                }
                enableSorting
                isLoading={isLoadingApptGrid}
                onSelectAppointment={onSelectAppointment}
                isInLineGrid
                onDragRowEnd={onDragRowEnd}
                onSelectNoShowAppointment={onSelectNoShowAppointment}
                enableRowSelection={enableMultipleAppointments}
                onSelectMultipleAppointments={onSelectMultipleAppointments}
                onSelectClearPendingTasks={onSelectClearPendingTasks}
              />
            ),
            badge: appointments?.people.queued.toString() ?? '',
            position: 'left',
            badgeColor: theme.palette.text.disabled,
          },
          {
            title: EAppointmentInLineTab.CRUISELATER,
            content: (
              <AppointmentsGrid
                key={`inline-cruise-later-table-${enableMultipleAppointments}`}
                appointments={
                  appointments?.rows.filter(
                    (item) => item.type === EAppointmentDBType.CRUISELATER,
                  ) ?? []
                }
                isLoading={isLoadingApptGrid}
                onSelectAppointment={onSelectAppointment}
                isInLineGrid
                onDragRowEnd={onDragRowEnd}
                onSelectNoShowAppointment={onSelectNoShowAppointment}
                enableRowSelection={enableMultipleAppointments}
                onSelectMultipleAppointments={onSelectMultipleAppointments}
                onSelectClearPendingTasks={onSelectClearPendingTasks}
              />
            ),
            badge: appointments?.people.cruise_later_counter.toString() ?? '',
            position: 'left',
            badgeColor: theme.palette.text.disabled,
          },
          {
            title: EAppointmentInLineTab.CALLBACK,
            content: (
              <AppointmentsGrid
                key={`inline-inline-callback-${enableMultipleAppointments}`}
                appointments={
                  appointments?.rows.filter((item) => item.type === EAppointmentDBType.CALLBACK) ??
                  []
                }
                enableSorting
                isLoading={isLoadingApptGrid}
                onSelectAppointment={onSelectAppointment}
                isInLineGrid
                onDragRowEnd={onDragRowEnd}
                onSelectNoShowAppointment={onSelectNoShowAppointment}
                enableRowSelection={enableMultipleAppointments}
                onSelectMultipleAppointments={onSelectMultipleAppointments}
                onSelectClearPendingTasks={onSelectClearPendingTasks}
              />
            ),
            badge: appointments?.people.callback.toString() ?? '',
            position: 'left',
            badgeColor: theme.palette.text.disabled,
          },
        ]}
      />
    </>
  )

  const mainTabs: ITab[] = [
    {
      title: EAppointmentMainTab.INLINE,
      content: inLineContent,
      badge: appointments?.people.pending.toString() ?? '',
      position: 'left',
      badgeColor: theme.palette.success.main,
    },
    {
      title: EAppointmentMainTab.INPROGRESS,
      content: (
        <>
          <AppointmentFilters
            appointments={unfilteredAppointments?.rows ?? []}
            displayHasPendingItems
            displayFilterByType
            displayFilterByDate
            displayManageMultiple
            keyword={keyword}
            onChangeKeyword={onChangeKeyword}
            hasPendingItems={hasPendingItems}
            onChangeHasPendingItems={onChangeHasPendingItems}
            keywordDate={keywordDate}
            onChangeKeywordDate={onChangeKeywordDate}
            onChangeType={onChangeType}
            isFetching={isFetchingUnfilteredAppointments}
            onChangeManageMultipleAppointment={setEnableMultipleAppointments}
            enableMultipleAppointments={enableMultipleAppointments}
            openMultipleAppointments={openMultipleAppointments}
            showMultipleAppointmentButton={
              enableMultipleAppointments && selectedAppointments.length > 1
            }
            inLineTab={false}
          />
          <AppointmentsGrid
            key={`finalized-table-${enableMultipleAppointments}`}
            appointments={appointments?.rows ?? []}
            isLoading={isLoadingApptGrid}
            onSelectAppointment={onSelectAppointment}
            enableRowSelection={enableMultipleAppointments}
            enableSorting
            onSelectMultipleAppointments={onSelectMultipleAppointments}
            onSelectClearPendingTasks={onSelectClearPendingTasks}
          />
        </>
      ),
      badge: appointments?.people.inprogress.toString() ?? '',
      position: 'left',
      badgeColor: theme.palette.primary.light,
    },
    {
      title: EAppointmentMainTab.FINALIZED,
      content: (
        <>
          <AppointmentFilters
            appointments={unfilteredAppointments?.rows ?? []}
            displayHasPendingItems
            displayFilterByType
            displayFilterByDate
            displayManageMultiple
            keyword={keyword}
            onChangeKeyword={onChangeKeyword}
            keywordDate={keywordDate}
            onChangeKeywordDate={onChangeKeywordDate}
            onChangeType={onChangeType}
            hasPendingItems={hasPendingItems}
            onChangeHasPendingItems={onChangeHasPendingItems}
            isFetching={isFetchingUnfilteredAppointments}
            onChangeManageMultipleAppointment={setEnableMultipleAppointments}
            enableMultipleAppointments={enableMultipleAppointments}
            openMultipleAppointments={openMultipleAppointments}
            showMultipleAppointmentButton={
              enableMultipleAppointments && selectedAppointments.length > 1
            }
            inLineTab={false}
          />
          <AppointmentsGrid
            key={`inprogress-table-${enableMultipleAppointments}`}
            appointments={appointments?.rows ?? []}
            isLoading={isLoadingApptGrid}
            onSelectAppointment={onSelectAppointment}
            enableRowSelection={enableMultipleAppointments}
            enableSorting
            onSelectMultipleAppointments={onSelectMultipleAppointments}
            onSelectClearPendingTasks={onSelectClearPendingTasks}
          />
        </>
      ),
      badge: appointments?.people.finished.toString() ?? '',
      position: 'left',
      badgeColor: theme.palette.error.main,
    },
    {
      title: EAppointmentMainTab.DELETED,
      content: (
        <>
          <AppointmentFilters
            appointments={unfilteredAppointments?.rows ?? []}
            displayHasPendingItems
            displayFilterByType={false}
            displayFilterByDate
            displayManageMultiple={false}
            keyword={keyword}
            onChangeKeyword={onChangeKeyword}
            keywordDate={keywordDate}
            onChangeKeywordDate={onChangeKeywordDate}
            hasPendingItems={hasPendingItems}
            onChangeHasPendingItems={onChangeHasPendingItems}
            isFetching={isFetchingUnfilteredAppointments}
            inLineTab={false}
          />
          <AppointmentsGrid
            appointments={appointments?.rows ?? []}
            isLoading={isLoadingApptGrid}
            onSelectAppointment={onSelectAppointment}
            onSelectClearPendingTasks={onSelectClearPendingTasks}
            enableSorting
          />
        </>
      ),
      badge: appointments?.people.deleted.toString() ?? '',
      position: 'right',
      badgeColor: theme.palette.error.main,
    },
  ]

  return (
    <>
      <Container sx={{ padding: 2 }} maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
          <Box>
            <Button
              variant="contained"
              color="success"
              startIcon={<AddCircleOutlineIcon />}
              onClick={validateActiveVoyageDates}>
              New Appointment
            </Button>
          </Box>
          <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
            Appointments Management
          </Typography>
        </Stack>
        <TabsContainer
          tabs={mainTabs}
          selectedTab={selectedMainTab}
          onChangeTab={onChangeMainTab}
        />
        <Stack direction="row" justifyContent="flex-end" columnGap={1} sx={{ pb: 2 }}>
          <Stack direction="row" sx={{ mr: 1 }} columnGap={1}>
            <CircleIcon sx={{ color: theme.palette.custom.blue }} />
            <Typography variant="body2" color="gray">
              BoA Visa
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mr: 1 }} columnGap={1}>
            <CircleIcon sx={{ color: theme.palette.primary.light }} />
            <Typography variant="body2" color="gray">
              Bookings
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mr: 1 }} columnGap={1}>
            <CircleIcon sx={{ color: theme.palette.custom.yellow }} />
            <Typography variant="body2" color="gray">
              Quotes
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mr: 1 }} columnGap={1}>
            <CircleIcon sx={{ color: theme.palette.success.main }} />
            <Typography variant="body2" color="gray" sx={{ mr: 1 }}>
              Events
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mr: 1 }} columnGap={1}>
            <CircleIcon sx={{ color: theme.palette.error.main }} />
            <Typography variant="body2" color="gray" sx={{ mr: 1 }}>
              Maildrop
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ mr: 1 }} columnGap={1}>
            <CircleIcon sx={{ color: theme.palette.custom.purple }} />
            <Typography variant="body2" color="gray">
              Casino
            </Typography>
          </Stack>
        </Stack>
      </Container>

      {/* New Appt Dialog */}
      <AppointmentForm
        key={`form-appointments-${isOpenNewAppt}`}
        isOpen={isOpenNewAppt}
        onLookup={onLookupNewAppt}
        onAccept={onAcceptNewAppt}
        onReject={onRejectNewAppt}
        persons={newApptAgents}
        isOnlyCallbacks={onlyCallback}
        isLoading={isLoading}
        cruiseDates={cruiseDates}
        onChangeCallbackDate={setNewApptDateToFetchHours}
        onChangeAppointmentType={setNewApptType}
        appointmentType={newApptType}
        assignedAgents={assignedAgents ?? []}
      />

      {isOpenManageAppt && appointmentSelected && (
        <AppointmentModal
          isOpen={isOpenManageAppt}
          agents={assignedAgents || []}
          appointment={appointmentSelected}
          person={appointmentSelected?.person!}
          searchedGuests={guestsByRoom || []}
          setIsOpenUpdateNoshow={setIsOpenUpdateNoshow}
          isOpenUpdateNoshow={isOpenUpdateNoshow}
          onClose={onRejectManageAppt}
          saveTime={saveTime}
          onChangeStatus={onChangeStatusAppt}
          onSelectAgent={onSelectAgent}
          onOpenMoveAppointment={() => setIsOpenMoveAppointmentModal(true)}
          onNoShowAppointment={onUpdateNoShow}
          onLookingUpStateRoom={handleOnLookingUpStateRoom}
          onUpdatePerson={onUpdatePerson}
          onRestoreAppointment={onRestoreAppointment}
          isLoadingAssignedAgents={isLoadingAssignedAgents}
          onDeleteAppointment={onDeleteAppointment}
          onOpenOtherForm={() => setIsOpenOtherForm(true)}
          openAppointmentCallbackModal={onOpenAppointmentCallbackForm}
          handleDelete={handleDeleteOthersResult}
          events={eventsSelected}
          isFetchingEvents={isFetchingEvents}
          selectedApptDetailsTab={selectedApptDetailsTab}
          onChangeDetailTab={onChangeDetailTab}
          getEventsByRoomTrigger={getEventsByRoomTrigger}
          getMaildropByRoomTrigger={getMaildropByRoomTrigger}
          maildrop={maildropSelected}
          isFetchingMaildrop={isFetchingMaildrop}
          openAppointmentQuoteModal={() => setIsOpenAppointmentQuoteModal(true)}
          openEditQuote={openEditQuoteModal}
          appointments={selectedAppointments}
          removeAppointmentFromSelectedAppointments={removeAppointmentFromSelectedAppointments}
          changeAppointmentSelected={changeAppointmentSelected}
          isLoadingAppointment={isLoadingAppointment}
          isFetchingSearchedGuests={isFetchingSearchedGuests}
        />
      )}

      {isOpenAppointmentCallbackForm && appointmentSelected && (
        <AppointmentCallbackForm
          isOpen={isOpenAppointmentCallbackForm}
          onClose={() => setIsOpenAppointmentCallbackForm(false)}
          agents={assignedAgents || []}
          appointment={appointmentSelected}
          onSubmit={onSubmitAppointmentAsCallback}
          isLoading={isLoadingMarkAppointmentAsCallback}
          cruiseDates={cruiseDates}
        />
      )}
      {!!appointmentSelected && (
        <MoveAppointmentModal
          cruisesData={cruisesData || []}
          appointment={{ ...appointmentSelected }}
          isOpen={isOpenMoveAppointmentModal}
          onClose={() => setIsOpenMoveAppointmentModal(false)}
          onSelectedPort={onSelectedPort}
        />
      )}

      {/* Dialog to mark an appointment as no show */}
      <ConfirmDialog
        title="Check appointment as No Show"
        message="Are you sure you want to mark this appointment as no show?"
        onAccept={onAcceptNoShowAppointment}
        onReject={onCloseNoShowDialog}
        isOpen={isOpenNoShow}
      />
      {/* Dialog to remove other result */}
      <ConfirmDialog
        title="Remove Other Result"
        message="Are you sure you want to remove this result?"
        onAccept={onUpdateOther}
        onReject={() => setIsOpenConfirmRemoveOtherResult(false)}
        isOpen={isOpenConfirmRemoveOtherResult}
      />

      {/* Dialog to clear pending items */}
      <ConfirmDialog
        title="Clear Pending Items"
        message="Are you sure you want to clear pending items on this appointment?"
        onAccept={onAcceptClearPendingTasks}
        onReject={onCloseClearPendingTasks}
        isOpen={isOpenConfirmClearPendingItems}
      />

      <AppointmentOtherForm
        key={`form-appointmentsOtherResult-${isOpenOtherForm}`}
        isOpen={isOpenOtherForm}
        onClose={() => setIsOpenOtherForm(false)}
        onSubmit={onAcceptOtherResult}
        isLoading={isLoadingOtherResult}
      />

      {isOpenAppointmentQuoteModal && appointmentSelected && (
        <QuoteForm
          isOpen={isOpenAppointmentQuoteModal}
          products={products ?? []}
          vessels={vessels ?? []}
          onClose={() => setIsOpenAppointmentQuoteModal(false)}
          onSubmit={onSubmitCreateQuote}
          isLoading={isCreatingSingleQuote || isAddingAttachmentToQuote}
          room={appointmentSelected.person?.room}
        />
      )}

      {!!selectedQuote && (
        <QuoteForm
          isOpen={isOpenEditQuoteModal}
          products={products ?? []}
          vessels={vessels ?? []}
          onClose={() => {
            setIsOpenEditQuoteModal(false)
            setSelectedQuote(undefined)
          }}
          onSubmit={onSubmitCreateQuote}
          isLoading={isCreatingSingleQuote || isEditingQuote || isAddingAttachmentToQuote}
          quote={selectedQuote}
          onSubmitEditQuote={onSubmitEditQuote}
          onDownloadQuoteAttachment={downloadQuoteAttachment}
          isLoadingDownloadAttachment={isFetchingQuoteAttachment}
          room={appointmentSelected?.person?.room}
        />
      )}
    </>
  )
}
export default Appointments
