import moment from 'moment'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { IPortStatsByAgent, IPortStatsByAgentTableProps } from './types'
import { headTableStyle, secondaryTableRowStyle } from '../../../../styles/generic-styles'
import { parseDateToString } from '../../../../utils/date'

const PortCardStatsByAgentTable: React.FC<IPortStatsByAgentTableProps> = ({ stats }) => {
  if (stats.length <= 0) return null
  return (
    <Paper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={headTableStyle}>Port / Date</TableCell>
            <TableCell sx={headTableStyle} align="center">
              Appointments
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Cards Offered
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Cards Approved
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Cards Pending
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Cards Denied
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              Cards Transferred to Folio
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map((stat: IPortStatsByAgent, index) => (
            <TableRow key={`row-${index}`}>
              <TableCell sx={secondaryTableRowStyle}>
                {stat.port}
                <br />
                {moment.utc(parseDateToString(stat.date)).format('MM/DD/YYYY')}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.appointments || 'None'}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.cards || 'None'}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.cardsApproved || 'None'}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.cardsPending || 'None'}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.cardsDenied || 'None'}
              </TableCell>
              <TableCell sx={secondaryTableRowStyle} align="center">
                {stat.cardsTransferredToFolio || 'None'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default PortCardStatsByAgentTable
