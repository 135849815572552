import { useCreateMutation, useGetQuery, useRemoveMutation } from '../../../redux/api/casino'
import { useAppSelector } from '../../../redux/hooks'
import { IGenericResponse } from '../../../types/generic'

export const useCasino = () => {
  const cruiseId = useAppSelector((state) => state.auth.activeCruise?.cruise_id || 0)

  const { data, isLoading, refetch } = useGetQuery(cruiseId, { skip: !cruiseId })
  const [invokeCreateCasinoRecord] = useCreateMutation()
  const [invokeRemoveCasinoRecord] = useRemoveMutation()

  const createCasinoRecord = async (
    casinoRecordData: any,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeCreateCasinoRecord(casinoRecordData).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const removeCasinoRecord = async (
    fileName: string,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeRemoveCasinoRecord({
        fileName,
        cruiseId,
      }).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return { isLoading, data, refetch, createCasinoRecord, removeCasinoRecord }
}
