import { IconButton, Paper, Stack, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { IBigNumberProps } from './types'
import { theme } from '../../../styles/mui-theme'

const BigNumber: React.FC<IBigNumberProps> = ({
  color = theme.palette.success.main,
  onSelectedBox,
  formattedNumber,
  label,
  labelFontSize,
  labelLineHeight,
  width,
  style,
  onEdit,
}) => (
  <Paper style={style || { width }} elevation={3} onClick={onSelectedBox}>
    <Stack padding={2} minWidth={theme.spacing(13)}>
      <Typography
        fontSize={labelFontSize}
        lineHeight={labelLineHeight}
        variant="button"
        color={color}
        align="center">
        {label}
      </Typography>
      <Stack direction="row" justifyContent="center">
        <Typography variant="h4" color={color}>
          {formattedNumber}
        </Typography>
        {onEdit && (
          <IconButton sx={{ color }} onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  </Paper>
)

export default BigNumber
