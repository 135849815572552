import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from './baseQuery'
import {
  ICustomQuoteResponse,
  IQuoteAttachment,
  IQuoteAttachmentRequest,
  IQuoteAttachmentResponse,
  IQuotePayload,
} from '../../types/quotes'
import { IGenericResponse } from '../../types/generic'

export const quotesApi = createApi({
  reducerPath: 'quotesApi',
  baseQuery,
  endpoints: (builder) => ({
    createQuote: builder.mutation<ICustomQuoteResponse, IQuotePayload>({
      query: ({
        agentId,
        amount,
        appointmentId,
        comment,
        destinationId,
        hasAttachment,
        sailDate,
        shipId,
        stateroomCategory,
        noBookReason,
        currency,
        exclusiveOnboardCredit,
        nextCruiseBonus,
        bonusSavingsValue,
        bonusValue,
      }) => ({
        url: `/quotes/${appointmentId}`,
        method: 'POST',
        body: {
          agentId,
          amount,
          comment,
          destinationId,
          hasAttachment,
          sailDate,
          shipId,
          currency,
          stateroomCategory,
          noBookReason,
          exclusiveOnboardCredit,
          nextCruiseBonus,
          bonusSavingsValue,
          bonusValue,
        },
      }),
    }),
    editQuote: builder.mutation<IGenericResponse, IQuotePayload>({
      query: ({
        agentId,
        amount,
        appointmentId,
        comment,
        destinationId,
        groupShellNumber,
        hasAttachment,
        quoteId,
        sailDate,
        shipId,
        currency,
        stateroomCategory,
        noBookReason,
        exclusiveOnboardCredit,
        nextCruiseBonus,
        bonusSavingsValue,
        bonusValue,
      }) => ({
        url: `/quotes/${appointmentId}`,
        method: 'PUT',
        body: {
          agentId,
          amount,
          comment,
          destinationId,
          groupShellNumber,
          hasAttachment,
          quoteId,
          sailDate,
          shipId,
          currency,
          stateroomCategory,
          noBookReason,
          exclusiveOnboardCredit,
          nextCruiseBonus,
          bonusSavingsValue,
          bonusValue,
        },
      }),
    }),
    addAttachment: builder.mutation<IGenericResponse, IQuoteAttachment>({
      query: (payload) => {
        const quoteId = (payload as any).get('quoteId')
        const groupShellNumber = (payload as any).get('groupShellNumber')
        return {
          url: `/quotes/${quoteId ?? groupShellNumber}/attach-file?isGroup=${!!groupShellNumber}`,
          method: 'POST',
          body: payload,
        }
      },
    }),
    getQuoteAttachment: builder.query<IQuoteAttachmentResponse, IQuoteAttachmentRequest>({
      query: (payload) => {
        const { quoteId, groupShellNumber } = payload
        return {
          url: `/quotes/${quoteId}/file?groupShellNumber=${groupShellNumber}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useCreateQuoteMutation,
  useEditQuoteMutation,
  useAddAttachmentMutation,
  useLazyGetQuoteAttachmentQuery,
} = quotesApi
