import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  status: yup.string().required('Status is required'),
  bookingOrigin: yup.string().required('Booking origin is required'),
  bookingNumber: yup
    .number()
    .max(9999999, 'Booking number should be less or equal than 7 digits')
    .required('Booking number is required')
    .typeError('Booking number must be a number'),
  comment: yup
    .string()
    .test(
      'comment-required',
      'Comment is required when other stateroom option is selected',
      (comment, context) => {
        const { stateRoomCategory } = context.parent
        return (
          stateRoomCategory !== 'Other (Add Comment)' ||
          (stateRoomCategory === 'Other (Add Comment)' && comment !== undefined)
        )
      },
    ),
})
