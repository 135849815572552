import { useState } from 'react'
import Button from '@mui/material/Button'
import { Box, Chip, Fab, Grid, Stack, Tooltip, Typography } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import { GridColDef } from '@mui/x-data-grid'
import ReplayIcon from '@mui/icons-material/Replay'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useProduct, useVessel } from './hook'
import { EArchiveOperation, EConfirmType, IConfirmDialogProps } from './types'
import { CellStyles } from './styles'
import ProductForm from '../../../components/ProductForm'
import SettingsLayout from '../../../components/SettingsLayout'
import Notify from '../../../utils/notify'
import VesselForm from '../../../components/VesselForm'
import ConfirmDialog from '../../../components/ConfirmDialog'
import DynamicDataGrid from '../../../components/DynamicDataGrid'
import { IDestinationSimple } from '../../../types/destinations'
import { IShipSimple } from '../../../types/ships'

const SettingsProductsVessels = () => {
  const [product, setProduct] = useState<IDestinationSimple | undefined>()
  const [isOpenProductDialog, setIsOpenProductDialog] = useState(false)
  const [isLoadingProduct, setIsLoadingProduct] = useState(false)

  const [vessel, setVessel] = useState<IShipSimple | undefined>()
  const [isOpenVesselDialog, setIsOpenVesselDialog] = useState(false)
  const [isLoadingVessel, setIsLoadingVessel] = useState(false)

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState<IConfirmDialogProps>()

  const { createEdit, archive, remove, products, isLoadingGetProducts, refetchProducts } =
    useProduct()
  const {
    createEdit: createEditVessel,
    archive: archiveVessel,
    remove: removeVessel,
    isLoadingVessels,
    vessels,
    refetchVessels,
  } = useVessel()

  const onConfirmCompleted = (message?: string) => {
    if (message) Notify.success(message)
    setIsConfirmOpen(false)
  }

  const confirmDialogDrivers = (id: number) => ({
    [EConfirmType.ARCHIVE_PRODUCT]: {
      title: 'Archive Product',
      message: 'Are you sure you want to archive this product?',
      onAccept: async () => {
        await archive({ id, action: EArchiveOperation.ARCHIVE }, onConfirmCompleted, () =>
          setIsConfirmOpen(false),
        )
        refetchProducts()
      },
    },
    [EConfirmType.REMOVE_PRODUCT]: {
      title: 'Delete Product',
      message:
        'Are you sure you want to remove this product? Please notice this action cannot be undone.',
      onAccept: async () => {
        await remove(id, onConfirmCompleted, () => setIsConfirmOpen(false))
        refetchProducts()
      },
    },
    [EConfirmType.RESTORE_PRODUCT]: {
      title: 'Restore Product',
      message: 'Are you sure you want to restore this product?',
      onAccept: async () => {
        await archive({ id, action: EArchiveOperation.RESTORE }, onConfirmCompleted, () =>
          setIsConfirmOpen(false),
        )
        refetchProducts()
      },
    },
    [EConfirmType.ARCHIVE_VESSEL]: {
      title: 'Archive Vessel',
      message: 'Are you sure you want to archive this vessel?',
      onAccept: async () => {
        await archiveVessel({ id, action: EArchiveOperation.ARCHIVE }, onConfirmCompleted, () =>
          setIsConfirmOpen(false),
        )
        refetchVessels()
      },
    },
    [EConfirmType.REMOVE_VESSEL]: {
      title: 'Delete Vessel',
      message:
        'Are you sure you want to remove this vessel? Please notice this action cannot be undone.',
      onAccept: async () => {
        await removeVessel(id, onConfirmCompleted, () => setIsConfirmOpen(false))
        refetchVessels()
      },
    },
    [EConfirmType.RESTORE_VESSEL]: {
      title: 'Restore Vessel',
      message: 'Are you sure you want to restore this vessel?',
      onAccept: async () => {
        await archiveVessel({ id, action: EArchiveOperation.RESTORE }, onConfirmCompleted, () =>
          setIsConfirmOpen(false),
        )
        refetchVessels()
      },
    },
  })

  const createProduct = () => {
    setProduct(undefined)
    setIsOpenProductDialog(true)
  }

  const editProduct = (theProduct: IDestinationSimple) => {
    setProduct({ id: theProduct.id, name: theProduct.name })
    setIsOpenProductDialog(true)
  }

  const onProductSaved = (message: string) => {
    Notify.success(message)
    setIsLoadingProduct(false)
    setIsOpenProductDialog(false)
  }

  const onProductError = () => {
    setIsLoadingProduct(false)
  }

  const onAcceptProduct = async (theProduct: IDestinationSimple): Promise<void> => {
    setIsLoadingProduct(true)
    await createEdit(
      {
        ...theProduct,
        isEdit: !!product,
      },
      onProductSaved,
      onProductError,
    )
  }

  const createVessel = () => {
    setVessel(undefined)
    setIsOpenVesselDialog(true)
  }

  const editVessel = (theShip: IShipSimple) => {
    setVessel({ id: theShip.id, name: theShip.name, code: theShip.code })
    setIsOpenVesselDialog(true)
  }

  const onVesselSaved = (message: string) => {
    Notify.success(message)
    setIsLoadingVessel(false)
    setIsOpenVesselDialog(false)
  }

  const onVesselError = () => {
    setIsLoadingVessel(false)
  }

  const onAcceptVessel = async (theVessel: IShipSimple): Promise<void> => {
    setIsLoadingVessel(true)
    await createEditVessel(
      {
        ...theVessel,
        isEdit: !!vessel,
      },
      onVesselSaved,
      onVesselError,
    )
  }

  const openConfirmDialog = (option: EConfirmType, id: number) => {
    setIsConfirmOpen(true)
    setConfirmProps(confirmDialogDrivers(id)[option])
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ id, row }) => <CellStyles disable={row.isDeleted}>{id}</CellStyles>,
    },
    {
      field: 'name',
      headerName: 'PRODUCT',
      flex: 1,
      cellClassName: 'product-cell',
      renderCell: ({ row }) => (
        <>
          <CellStyles m="1rem 0" disable={row.isDeleted}>
            {row.name}
          </CellStyles>
          {row.isDeprecated > 0 && <Chip label="Archived" sx={{ ml: 1 }} />}
          {row.isDeleted > 0 && <Chip label="Deleted" sx={{ ml: 1 }} />}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 150,
      sortable: false,
      renderCell: ({ id, row }) => {
        const displayEditArchiveDelete = !row.isDeleted && !row.isDeprecated
        const displayRestore = row.isDeleted || row.isDeprecated
        return (
          <>
            {displayEditArchiveDelete && (
              <>
                <Tooltip title="Edit product" sx={{ mr: 1 }}>
                  <Fab color="secondary" size="small" onClick={() => editProduct(row)}>
                    <EditOutlinedIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Archive Product" sx={{ mr: 1 }}>
                  <Fab
                    color="secondary"
                    size="small"
                    onClick={() => openConfirmDialog(EConfirmType.ARCHIVE_PRODUCT, Number(id))}>
                    <ArchiveOutlinedIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Delete Product">
                  <Fab
                    color="error"
                    size="small"
                    onClick={() => openConfirmDialog(EConfirmType.REMOVE_PRODUCT, Number(id))}>
                    <DeleteIcon />
                  </Fab>
                </Tooltip>
              </>
            )}
            {displayRestore ? (
              <Tooltip title="Restore Product" sx={{ mr: 1 }}>
                <Fab
                  sx={{ display: 'flex' }}
                  color="inherit"
                  size="small"
                  onClick={() => openConfirmDialog(EConfirmType.RESTORE_PRODUCT, Number(id))}>
                  <ReplayIcon />
                </Fab>
              </Tooltip>
            ) : null}
          </>
        )
      },
    },
  ]

  const columnsVessels: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ id, row }) => <CellStyles disable={row.isDeleted}>{id}</CellStyles>,
    },
    {
      field: 'name',
      headerName: 'VESSEL',
      flex: 0.7,
      cellClassName: 'product-cell',
      renderCell: ({ row }) => (
        <>
          <CellStyles m="1rem 0" disable={row.isDeleted}>
            {row.name}
          </CellStyles>
          {row.isDeprecated > 0 && <Chip label="Archived" sx={{ ml: 1 }} />}
          {row.isDeleted > 0 && <Chip label="Deleted" sx={{ ml: 1 }} />}
        </>
      ),
    },
    {
      field: 'code',
      headerName: 'CODE',
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 200,
      sortable: false,
      renderCell: ({ id, row }) => {
        const displayEditArchiveDelete = !row.isDeleted && !row.isDeprecated
        const displayRestore = row.isDeleted || row.isDeprecated
        return (
          <>
            {displayEditArchiveDelete && (
              <>
                <Tooltip title="Edit Vessel" sx={{ mr: 1 }}>
                  <Fab color="secondary" size="small" onClick={() => editVessel(row)}>
                    <EditOutlinedIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Archive Vessel" sx={{ mr: 1 }}>
                  <Fab
                    color="secondary"
                    size="small"
                    onClick={() => openConfirmDialog(EConfirmType.ARCHIVE_VESSEL, Number(id))}>
                    <ArchiveOutlinedIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Delete Vessel">
                  <Fab
                    color="error"
                    size="small"
                    onClick={() => openConfirmDialog(EConfirmType.REMOVE_VESSEL, Number(id))}>
                    <DeleteIcon />
                  </Fab>
                </Tooltip>
              </>
            )}
            {displayRestore ? (
              <Tooltip title="Restore Vessel" sx={{ mr: 1 }}>
                <Fab
                  color="inherit"
                  size="small"
                  onClick={() => openConfirmDialog(EConfirmType.RESTORE_VESSEL, Number(id))}>
                  <ReplayIcon />
                </Fab>
              </Tooltip>
            ) : null}
          </>
        )
      },
    },
  ]

  return (
    <SettingsLayout>
      <ProductForm
        key={`form-product-${product?.id || isOpenProductDialog}`}
        isOpen={isOpenProductDialog}
        isLoading={isLoadingProduct}
        product={product}
        onAccept={onAcceptProduct}
        onReject={() => setIsOpenProductDialog(false)}
      />

      <VesselForm
        key={`form-vessel-${vessel?.id || isOpenVesselDialog}`}
        isOpen={isOpenVesselDialog}
        isLoading={isLoadingVessel}
        vessel={vessel}
        onAccept={onAcceptVessel}
        onReject={() => setIsOpenVesselDialog(false)}
      />

      <ConfirmDialog
        title={confirmProps?.title!}
        message={confirmProps?.message}
        onAccept={confirmProps?.onAccept!}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
        isOpen={isConfirmOpen}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
            <Box>
              <Button
                variant="contained"
                color="success"
                startIcon={<AddCircleOutlineIcon />}
                onClick={createProduct}
                sx={{ mb: 2 }}>
                New Product
              </Button>
            </Box>
            <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
              Products
            </Typography>
          </Stack>
          <Box>
            <DynamicDataGrid
              rows={products}
              columns={columns}
              isLoading={isLoadingGetProducts}
              emptyMessage="No products created yet."
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
            <Box>
              <Button
                variant="contained"
                color="success"
                startIcon={<AddCircleOutlineIcon />}
                onClick={createVessel}
                sx={{ mb: 2 }}>
                New Vessel
              </Button>
            </Box>
            <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
              Vessels
            </Typography>
          </Stack>

          <Box>
            <DynamicDataGrid
              rows={vessels}
              columns={columnsVessels}
              isLoading={isLoadingVessels}
              emptyMessage="No vessels created yet."
            />
          </Box>
        </Grid>
      </Grid>
    </SettingsLayout>
  )
}

export default SettingsProductsVessels
