export enum EStatsTab {
  VOYAGE = 'voyage',
  MONTHLY = 'monthly',
  CARDS = 'cards',
}

export enum EVoyageAttribute {
  NONE,
  VOYAGE_TARGET,
  STATEROOM_FORECAST,
  PENETRATION_GOAL,
}
