import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  destinationId: yup.number().required('Product is required').typeError('Product is required'),
  shipId: yup.number().required('Vessel is required').typeError('Vessel is required'),
  amount: yup.number(),
  groupQuoteItems: yup.array().of(
    yup.object().shape({
      amount: yup.number(),
      stateroom_number: yup.string(),
    }),
  ),
})
