import { IActiveInactiveCruisePayload } from './types'
import {
  useActiveInactiveCruiseMutation,
  useDeleteCruiseMutation,
  useGetCruisesQuery,
  useLazyGetActiveQuery,
} from '../../../redux/api/cruises'
import {
  useAssignAgentMutation,
  useUnassignAgentMutation,
  useGetQuery,
  useLazyGetAssignedAgentsQuery,
} from '../../../redux/api/users'
import { EUserRole, IAgentPayload, IDeleteAgentPayload } from '../../../types/users'
import { IGenericResponse } from '../../../types/generic'
import { useAppSelector } from '../../../redux/hooks'

export const useVoyages = () => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const { data: allUsers, isLoading: isLoadingUsers } = useGetQuery(undefined, {
    skip: loggedUser?.role === EUserRole.AGENT,
  })
  const {
    data: cruisesData,
    isLoading,
    refetch,
    isFetching: isFetchingCruisesData,
  } = useGetCruisesQuery(undefined, { skip: loggedUser?.role === EUserRole.AGENT })
  const [deleteCruise] = useDeleteCruiseMutation()
  const [activeInactiveCruise, { isLoading: isLoadingActiveInactiveCruise }] =
    useActiveInactiveCruiseMutation()
  const [getActiveCruiseTrigger, { isFetching: isFetchingActiveCruise }] = useLazyGetActiveQuery()
  const [unassignAgent, { isLoading: isLoadingUnassignAgent }] = useUnassignAgentMutation()
  const [getAssignedAgentsTrigger, { data: assignedAgents, isFetching: isLoadingAssignedAgents }] =
    useLazyGetAssignedAgentsQuery()
  const [assignCruiseAgent, { isLoading: isAssignAgentLoading }] = useAssignAgentMutation()

  const onActiveChange = async (
    values: IActiveInactiveCruisePayload,
    onSuccess: (message: string) => void,
  ): Promise<void> => {
    try {
      const response = await activeInactiveCruise(values).unwrap()
      await refetch()
      await getActiveCruiseTrigger().unwrap()
      onSuccess(response.message)
    } catch (error) {
      console.error(error)
    }
  }

  const remove = async (
    cruiseId: number,
    onSuccess: () => void,
    onError: () => void,
  ): Promise<void> => {
    try {
      await deleteCruise(cruiseId).unwrap()
      refetch()
      onSuccess()
    } catch (error) {
      console.error(error)
      onError()
    }
  }

  const assignAgent = async (
    payload: IAgentPayload,
    onSuccess?: (message: string) => void,
  ): Promise<void> => {
    try {
      const result: IGenericResponse = await assignCruiseAgent(payload).unwrap()
      if (onSuccess) onSuccess(result.message)
    } catch (error) {
      console.error(error)
    }
  }

  const removeAgent = async (
    agentToRemove: IDeleteAgentPayload,
    onSuccess?: (message: string) => void,
  ): Promise<void> => {
    try {
      const result: IGenericResponse = await unassignAgent(agentToRemove).unwrap()
      if (onSuccess) onSuccess(result.message)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    cruisesData,
    isLoading,
    allUsers: allUsers?.rows,
    isLoadingUsers,
    assignedAgents,
    isAssignAgentLoading,
    isLoadingAssignedAgents,
    isLoadingActiveInactiveCruise,
    isFetchingCruisesData,
    isFetchingActiveCruise,
    isLoadingUnassignAgent,
    refetch,
    remove,
    assignAgent,
    onActiveChange,
    removeAgent,
    getAssignedAgentsTrigger,
  }
}
